import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/timer';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    panel: {
        backgroundColor: "#efb001",
        borderRadius: 4,
        margin: "4px 4px 2px 2px",
        padding: 4,
        textAlign: "center"
    },
    timer: {
        backgroundColor: "#333",
        color: "#f00",
        fontSize: 24,
        fontWeight: 600,
        marginLeft: "calc(50% - 33px)",
        marginTop: 8,
        width: 70,
        '@media (min-width: 800px)': {
            fontSize: 30,
            width: 86,
            marginLeft: "calc(50% - 42px)",
        },
        '@media (min-width: 1000px)': {
            fontSize: 36,
            width: 102,
            marginLeft: "calc(50% - 51px)",
        },
        '@media (min-width: 1200px)': {
            fontSize: 42,
            width: 120,
            marginLeft: "calc(50% - 60px)",
        },
        '@media (min-width: 1400px)': {
            fontSize: 48,
            width: 138,
            marginLeft: "calc(50% - 69px)",
        },
        '@media (min-width: 1600px)': {
            fontSize: 54,
            width: 156,
            marginLeft: "calc(50% - 78px)",
        }
    },
    typography: {
        fontSize: 9,
        fontWeight: 600,
        '@media (min-width: 800px)': {
            fontSize: 10
        },
        '@media (min-width: 1000px)': {
            fontSize: 12
        },
        '@media (min-width: 1200px)': {
            fontSize: 14
        },
        '@media (min-width: 1400px)': {
            fontSize: 16
        },
        '@media (min-width: 1600px)': {
            fontSize: 18
        }
    }
}));

const Timer = props => {
    const classes = useStyles();
    const timer = props.timer;

    useEffect(() => {
        setInterval(function() {
            props.incrementSeconds();
        }, 1000 );
    // eslint-disable-next-line
    }, []);

    function fnConvertSecondsToTime(seconds) {
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;
        seconds = seconds % 60;

        return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    return (
        <Card className={classes.panel}>
            <CardContent className={classes.content}>
                <Typography className={classes.typography}>
                    N° PIQUE: {props.piqueId > 0 ? props.piqueId : ''}
                </Typography>
                <Typography className={classes.typography}>
                    N° OPERACIÓN: {props.ordentrabajoId > 0 ? props.ordentrabajoId : ''}
                </Typography>
                <div className={classes.timer} >
                    {fnConvertSecondsToTime(timer.seconds)}
                </div>
                <Typography className={classes.typography}>
                    TIEMPO DE SERVICIO
                </Typography>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = state => ({
    ordentrabajoId: state.workorder.ordenTrabajoId,
    piqueId: state.workorder.piqueId,
    timer: state.timer
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Timer);