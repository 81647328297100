import React, { useEffect } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/additive';

const Additive = props => {
    const additive = props.additive;

    useEffect(() => {
        props.requestAdditives();
    // eslint-disable-next-line
    }, []);

    function handleNext() {
        props.incrementRanking();
        props.requestAdditives();
    }

    function handleBack() {
        props.decrementRanking();
        props.requestAdditives();
    }
    return(
        <div>
            {additive.isLoading && (<div style={{ textAlign: "center", minHeight: 485 }}>
                <CircularProgress color="secondary" />
            </div>)}
            {!additive.isLoading && (<Grid container justify="center" spacing={2} style={{ minHeight: 500 }}>
                {additive.additives.map((item, key) => (
                    <Grid key={key} item>
                        <Grid direction="column" container justify="center" spacing={2}>
                            <Grid item>
                                <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                    <IconButton onClick={() => {
                                        if(item.stock > 0){
                                            let detail = {
                                                productoId: item.productoId,
                                                dProducto: item.dProducto,
                                                cantidad: 1,
                                                precioVenta: item.precioVenta,
                                                puntos: item.puntos,
                                                stock: item.stock - 1,
                                                codigoCategoria: item.codigoCategoria
                                            };
                                            props.addDetailProduct(detail);
                                        }
                                    }}>
                                        <img style={{ height: 345, width: 315 }} src={item.imagenUri} alt={item.productoId}/>
                                    </IconButton>
                                </Paper> 
                            </Grid>
                            <Grid style={{ textAlign: "-webkit-center" }} item>
                                <Paper style={{ width: 315, textAlign:"center", boxShadow: "none" }}>
                                    <Typography style={{fontSize: 15, fontWeight: 600}}>{item.detalle}</Typography>
                                </Paper>
                            </Grid>
                            <Grid item>
                                <Grid direction="row" container justify="center" spacing={2}>
                                    <Grid  item>
                                        <Paper className="price-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f5f538" }} >
                                            <Typography style={{fontSize: 22, fontWeight: 600, lineHeight: 1 }}>{item.precioVenta.toFixed(2)}</Typography>
                                        </Paper>
                                    </Grid>
                                    {item.puntos > 0 && (<Grid  item>
                                        <Paper className="points-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f00", color:"#fff" }} >
                                            <Typography style={{fontSize: 22, fontWeight: 600, lineHeight: 1 }}>{item.puntos}</Typography>
                                        </Paper>
                                    </Grid>)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>)}
            <MobileStepper style={{backgroundColor: "#fff"}}
              steps={3}
              position="static"
              variant="text"
              activeStep={additive.ranking}    
              nextButton={
                  <Fab size="large" color="secondary" onClick={handleNext} disabled={additive.isLoading || additive.ranking === 2} >
                      <Icon className="fas fa-arrow-right" style={{ fontSize: 34 }}/>
                  </Fab>
              }
              backButton={
                <Fab size="large"  color="secondary" onClick={handleBack} disabled={additive.isLoading || additive.ranking === 0} >
                    <Icon className="fas fa-arrow-left" style={{ fontSize: 34 }}/>
                </Fab>
              }
            >
            </MobileStepper>
        </div>
    )
}

const mapStateToProps = state => ({
    additive: state.additive
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Additive);