import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardActions, CardContent, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/order';

const useStyles = makeStyles(() => ({
    content: {
        height: 145,
        overflow: "auto",
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    panel: {
        borderRadius: 4,
        margin: "4px 4px 2px 2px",
        height: 190,
        padding: 4,
        textAlign: "center"
    }
}));

const Product = props => {
    const classes = useStyles();
    const order = props.order;
    console.log(props.order)
    return (
        <Card className={classes.panel}>
            <CardContent className={classes.content}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>PRODUCTOS</TableCell>
                            <TableCell align="right">CANT</TableCell>
                            <TableCell align="right">TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {order.details.map((item, key) => (
                            <TableRow key={key}>
                                <TableCell>{item.dProducto}</TableCell>
                                <TableCell align="right">{item.cantidad}</TableCell>
                                <TableCell align="right">{item.precioVenta*item.cantidad}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions>
                {order.details.length > 0 && (<Button color="secondary" size="small" variant="contained" onClick={props.onOpenProductDialog} >Ver</Button>)}
                <span>{props.fin}</span>
            </CardActions>
        </Card>
    );
};

const mapStateToProps = state => ({
    order: state.order,
    fin: state.client.cliente.fin
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Product);