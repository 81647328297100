import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/rating';

const RatingDialog = props => {
    const rating = props.rating;

    function onHandleSave(value) {
        props.onCloseDialog(value);
    }

    return(
        <Dialog aria-labelledby="station-dialog-title" open={rating.open} maxWidth="md" fullScreen >
            <DialogTitle id="station-dialog-title" style={{ backgroundColor: "#000", color: '#fff' }}>Calificación</DialogTitle>
            <DialogContent style={{ backgroundColor: "#000", color: '#fff' }} dividers >
                <Typography style={{ fontSize: 20, textAlign: "center" }}>Su opinión es muy importante, ayudenos a mejorar...!</Typography>
                <div style={{ display:"flex", flexDirection:"row", justifyContent: "center" }}>
                    <div style={{ padding: "8px 36px", textAlign: "center" }}>
                        <IconButton onClick={onHandleSave.bind(props, 'A')}>
                            <img style={{ height: 325, width: 325 }} src="images/bueno.png" alt="bueno"/>
                        </IconButton>
                        <Typography>BUENO</Typography>
                    </div>
                    <div style={{ padding: "8px 36px", textAlign: "center" }}>
                        <IconButton onClick={onHandleSave.bind(props, 'B')}>
                            <img style={{ height: 325, width: 325 }} src="images/regular.png" alt="regular"/>
                        </IconButton>
                        <Typography>REGULAR</Typography>
                    </div>
                    <div style={{ padding: "8px 36px", textAlign: "center" }}>
                        <IconButton onClick={onHandleSave.bind(props, 'C')} >
                            <img style={{ height: 325, width: 325 }} src="images/malo.png" alt="malo"/>
                        </IconButton>
                        <Typography>MALO</Typography>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    rating: state.rating
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(RatingDialog);