import React from 'react';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import IconActivity from "../icons/icon";

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/process';

const useStyles = makeStyles(() => ({
    activity: {
        color: '#333',
        height: 46,
        width: 46,
        '@media (min-width: 800px)': {
            height: 59,
            width: 59
        },
        '@media (min-width: 1000px)': {
            height: 72,
            width: 72
        },
        '@media (min-width: 1200px)': {
            height: 84,
            width: 84
        },
        '@media (min-width: 1400px)': {
            height: 97,
            width: 97
        },
        '@media (min-width: 1600px)': {
            height: 110,
            width: 110
        }
    },
    alert: {
        backgroundColor: '#f00 !important'
    },
    button: {
        color: '#333'
    },
    icon: {
        fontSize: 32,
        '@media (min-width: 800px)': {
            fontSize: 40
        },
        '@media (min-width: 1000px)': {
            fontSize: 48
        },
        '@media (min-width: 1200px)': {
            fontSize: 56
        },
        '@media (min-width: 1400px)': {
            fontSize: 64
        },
        '@media (min-width: 1600px)': {
            fontSize: 72
        }
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    panel: {
        backgroundColor: '#14e014',
        borderRadius: 4,
        margin: '4px 2px 2px 4px',
        minHeight: 121,
        padding: 2,
        textAlign: 'center',
        '@media (min-width: 800px)': {
            minHeight: 154,
            padding: 2
        },
        '@media (min-width: 1000px)': {
            minHeight: 187,
            padding: 4
        },
        '@media (min-width: 1200px)': {
            minHeight: 220,
            padding: 4
        },
        '@media (min-width: 1400px)': {
            minHeight: 253,
            padding: 6
        },
        '@media (min-width: 1600px)': {
            minHeight: 286,
            padding: 8
        }
    },
    typography: {
        fontSize: 8,
        fontWeight: 600,
        lineHeight: 1.2,
        '@media (min-width: 800px)': {
            fontSize: 10,
            lineHeight: 1.2
        },
        '@media (min-width: 1000px)': {
            fontSize: 12,
            lineHeight: 1.2
        },
        '@media (min-width: 1200px)': {
            fontSize: 14,
            lineHeight: 1.5
        },
        '@media (min-width: 1400px)': {
            fontSize: 16,
            lineHeight: 1.5
        },
        '@media (min-width: 1600px)': {
            fontSize: 18,
            lineHeight: 1.5
        }
    }
}));

const Activity = props => {
    const classes = useStyles();
    const activity = props.process.activities[props.process.index];

    return (
        <Card className={`${classes.panel} ${activity.enciendeLuz ? classes.alert : ''}` }>
            <CardContent className={classes.content}>
                <IconActivity className={classes.activity} name={activity.nombreImagen}/>
                <div>
                    <IconButton className={classes.button} disabled={activity.orden === 1} onClick={props.decreaseIndex}>
                        <Icon className={clsx("fas fa-chevron-left", classes.icon)} />
                    </IconButton>
                    <IconButton className={classes.button} disabled={(activity.orden === props.process.activities.length) || props.piqueId === 0} onClick={props.increaseIndex}>
                        <Icon className={clsx("fas fa-chevron-right", classes.icon)} />
                    </IconButton>
                    <Typography className={classes.typography}>{activity.dActividad}</Typography>
                </div>
            </CardContent>
        </Card>
    )

}

const mapStateToProps = state => ({
    piqueId: state.workorder.piqueId,
    process: state.process
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Activity);