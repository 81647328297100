import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { Switch } from 'react-router-dom';
import Loading from './components/Loading';
import NotFound from './components/NotFound';
import sessionRoutes from './routes/sessionRoutes';
import { createMyTheme } from './helpers/Themes';
import _ from 'lodash';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from './reducers/session';


const App = ({ color, totalLoad, createInterceptor, getSession }) => {
  const theme = createMyTheme(color);

  useEffect(() => {
    createInterceptor();
    getSession();
  // eslint-disable-next-line
  }, []);

  if(totalLoad < 4) {
    return (
      <MuiThemeProvider theme={theme}>
        <Loading />
      </MuiThemeProvider>
    )
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Switch>
        { _.map(sessionRoutes, (route, key) => {
          const { component, path } = route;
          const Component = component;
          
          return (
            <Route
              exact
              path={path}
              key={key}
              render={ route => <Component route={route}/> }
            />
          )
        })}
        <Route component={NotFound} />
      </Switch>
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => ({
  color: state.user.color,
  totalLoad: state.user.totalLoad
});

export default connect(
  mapStateToProps,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(App);
