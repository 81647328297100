import { ProductApiUrl } from '../config';
import axios from 'axios';

import {
    ADD_DETAIL_PRODUCT,
    CHANGE_NAV_FILTER,
    CLOSE_MESSAGE_DIALOG,
    ERROR_MESSAGE_DIALOG,
    OPEN_MESSAGE_DIALOG,
    QUESTION_MESSAGE_DIALOG,
    RECEIVE_FILTER_LIST,
    REQUEST_FILTER_LIST,
    UPDATE_FIELD_FILTER,
    SET_ACTION_DIALOG,
    UPDATE_FILTER_DEFAULT,
    UPDATE_STATE_ORDER
} from '../constants/actionTypes';

const initialState = {
    codigo: '',
    codSubCategoria: 'FAC',
    isLoading: false,
    filters: [],
    default: 0
};

export const actionCreators = {
    changeNav: (_e, value) => (dispatch) => {
        dispatch({ type: CHANGE_NAV_FILTER, value });
        dispatch(actionCreators.requestFilters());
    },
    onUpdateField: (key, value) => ({ type: UPDATE_FIELD_FILTER, key, value }),

    requestFilters: () => async (dispatch, getState) => {
        if(getState().filter.isLoading) {
            return;
        }
        dispatch({ type: REQUEST_FILTER_LIST });

        let codSubCategoria = getState().filter.codSubCategoria;
        let modeloId = getState().client.vehiculo.modeloId;
        let url = `${ProductApiUrl}/api/v1/producto/filtro?categoriaId=2&modeloEquipo=${modeloId}&codigoSubCategoria=${codSubCategoria}`;
        var response = await axios.get(url);
        var filters = response.data;

        if(filters.length > 0 && filters[0].productoId > 0) {
            let codigo = filters[0].codigo;

            dispatch({ type: UPDATE_FIELD_FILTER, key: 'codigo', value: codigo });
            dispatch({ type: UPDATE_FILTER_DEFAULT, default: filters[0].productoId });
        }

        dispatch({ type: RECEIVE_FILTER_LIST, filters });
    },

    findProduct: () => async (dispatch, getState) => {
        let codigo = getState().filter.codigo;

        if(codigo === '') {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Ingrese un código.' })
            return;
        }

        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Buscando producto' });

        try {
            let url = `${ProductApiUrl}/api/v1/Producto/ProductoporCodigo?almacenId=1&Codigo=${codigo}`;
            let response = await axios.get(url);
            let producto = response.data;

            let details = [...getState().order.details];

            let index = details.findIndex(function(item){
                return item.productoId === producto.productoId;
            });

            if(producto.stock - 1 < 0) {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'El código ingresado no cuenta con stock disponible.' });
                return;
            }

            if(index >= 0) {
                details[index].cantidad += 1;
                details[index].stock -= 1;
            }
            else {
                details.push({
                    productoId: producto.productoId,
                    dProducto: producto.dProducto,
                    cantidad: 1,
                    precioVenta: producto.precio,
                    puntos: producto.puntos,
                    stock: producto.stock - 1
                });
            }

            dispatch({ type: ADD_DETAIL_PRODUCT, details });
            dispatch({ type: UPDATE_STATE_ORDER });
            dispatch({ type: CLOSE_MESSAGE_DIALOG });

            if(producto.productoId !== getState().filter.default && getState().client.vehiculo.modeloId > 0) {
                dispatch({ type: UPDATE_FILTER_DEFAULT, default: producto.productoId });
                dispatch( { type: SET_ACTION_DIALOG, action: 'filter' });
                dispatch({ type: QUESTION_MESSAGE_DIALOG, message: '¿Quiere actualizar el filtro asignado al modelo-marca del vehículo?' });
            }
        } catch (error) {
            let response = error.response;

            if(response) {
                if(response.status === 404){
                    dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'El código ingresado no existe.' });
                }
                else{
                    dispatch({ type: ERROR_MESSAGE_DIALOG, message: response.data.message.toString() });
                }
            }
            else {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: error });
            }
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CHANGE_NAV_FILTER:
            return {
                ...state,
                codSubCategoria: action.value,
                codigo: ''
            };
        case RECEIVE_FILTER_LIST:
            return {
                ...state,
                isLoading: false,
                filters: action.filters
            };
        case REQUEST_FILTER_LIST:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_FIELD_FILTER:
            return {
                ...state,
                [action.key]: action.value
            }
        case UPDATE_FILTER_DEFAULT:
            return {
                ...state,
                default: action.default
            }
        default:
            return state;
    }

};