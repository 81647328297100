import { IdentityUrl } from '../config';
import React, { useEffect } from 'react';
import { useDispatch} from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';

import {
    TOGGLE_SHOW_ORDER,
    TOGGLE_SHOW,
    TOGGLE_OFERTA,
    OPEN_DIALOG_PRODUCT_OFERTA,
    REQUEST_OFERTA_LIST
} from '../constants/actionTypes';


import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/user';
import { actionCreators as actionCreatorsOferta} from '../reducers/oferta';
//import ProductsDialog from './ProductsDialog';


const useStyles = makeStyles(() => ({
    avatar: {
        backgroundColor: '#efb001',
        fontSize: '1.25rem',
        height: '2.25rem',
        marginRight: '0.5rem',
        width: '2.25rem'
    },
    button: {
        color: '#666',
        fontSize: 11
    },
    cardActions: {
        backgroundColor: '#f5f5f5', 
        borderColor: 'rgba(0,0,0,.2)',
        borderTop: '1px solid #ccc'
    },
    cardContent: {
        padding: 0, 
        width: 315
    },
    grow: {
        flexGrow: 1
    },
    iconButton: {
        padding: 8
    },
    logo: {
        height: 44
    },
    marginContent: {
        display: 'flex', 
        margin: 16
    },
    picture: {
        backgroundColor: '#efb001', 
        fontSize: '2.5rem',
        marginRight: 16,
        height: 84, 
        width: 84
    },
    primaryText: {
        fontSize: '0.875rem',
        lineHeight: 1
    },
    secondaryText: {
        color: 'rgba(0, 0, 0, 0.54);',
        fontSize: '0.8125rem'
    }
}));

const AppMenu = props => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dispatch = useDispatch();

    // const user = props.user;

    useEffect(() => {
        setInterval(function() {
            props.incrementSeconds();
        }, 1000 );
       
    // eslint-disable-next-line
    }, []);

    function handleAddAccount() {
        setAnchorEl(null);
        window.open(`${IdentityUrl}/?continue=${encodeURIComponent(window.location.href)}`, '_blank')
    }

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }

    const handleClickOpt  = value => () => {
        // dispatch({ type: TOGGLE_OFERTA, m});
        /*
        alert(value)
        if (props.showx === false ){
            alert(props.showx)
            return;
        }
        */
        var  r = props.parameter === "OFERTA" ? "" : value;

        dispatch({ type: TOGGLE_SHOW_ORDER});
        dispatch({ type: TOGGLE_OFERTA, r});
        dispatch({ type: TOGGLE_SHOW});
      };

    function handleClose() {
        setAnchorEl(null);
    }

    function handleClickUser(authuser) {
        setAnchorEl(null);
        if(authuser >= 0) {
            window.open(`/?authuser=${authuser}`, '_blank');
        }
        else {
            window.open(`${IdentityUrl}/?continue=${encodeURIComponent(window.location.origin)}`, '_blank');
        }
        
    }

    function handleSignOut() {
        window.location.href = `${IdentityUrl}/logout?continue=${encodeURIComponent(window.location.href)}`;
    }

    function handleOferta(){
       dispatch({type: OPEN_DIALOG_PRODUCT_OFERTA});
       dispatch( actionCreatorsOferta.requestOferta());
    }
    

    function fnConvertSecondsToTime(given_seconds) {
        let hours = Math.floor(given_seconds / 3600);
        let minutes = Math.floor((given_seconds - (hours * 3600)) / 60);
        let seconds = given_seconds - (hours * 3600) - (minutes * 60);

        return hours.toString().padStart(2, '0') + ':' + 
            minutes.toString().padStart(2, '0') + ':' + 
            seconds.toString().padStart(2, '0');
    }
    
    return(
        <AppBar position='static'>
            <Toolbar>
                <img src='./images/logo_okey.svg' alt='logo' className={classes.logo}></img>
                <div className={classes.grow}/>
                <div>
        
                    {/* <IconButton aria-label='toogle shop'  style= {{backgroundColor: 'red', display : props.showx ?  'none' : 'inline', left: '-180px'}} className={classes.iconButton} color='inherit' onClick={handleClickOpt("OFERTA")}>
                        <Avatar className={classes.avatar}   style= {{ left: '1px'}}>
                            {props.showOrder ? <Icon className="fas fa-bullhorn"/> : <Icon className="fas fa-bullhorn" />}
                        </Avatar>
                    </IconButton> */}
                    
                    {/* Agregado Alida  barcode  */}
                    <IconButton aria-label='toogle shop' className={classes.iconButton}   color='inherit' variant="contained" onClick={(e)=> handleOferta(e)}>
                        <Avatar className={classes.avatar}  style= {{backgroundColor: 'red'}}>
                            {props.showOrder ? <Icon className="fa fa-gavel"/> : <Icon className="fa fa-gavel" />}
                        </Avatar>
                        
                    </IconButton>
                    
                    <IconButton aria-label='toogle shop' className={classes.iconButton}  style= {{display : props.show ?  'none' : 'inline'}}  color='inherit' onClick={props.toggleShowOrder}>
                        <Avatar className={classes.avatar}>
                            {props.showOrder ? <Icon className="fas fa-shopping-cart"/> : <Icon className="fas fa-shopping-cart" />}
                        </Avatar>
                    </IconButton>
                    <IconButton aria-label='Account of user' className={classes.iconButton} onClick={handleClick}>
                        <Avatar src={props.user.avatar ? `data:image/jpeg;base64,${props.user.avatar}` : ''} className={classes.avatar}>
                            {props.user.nombre.charAt(0).toUpperCase() + (props.user.nombre.split(' ').length > 1 ? props.user.nombre.split(' ')[1].charAt(0).toUpperCase() : '') }
                        </Avatar>
                    </IconButton>
                    <Popover
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                          }}
                        >
                        <Card>
                            <CardContent className={classes.cardContent}>
                                <div className={classes.marginContent}>
                                    <div>
                                    <Avatar src={props.user.avatar ? `data:image/jpeg;base64,${props.user.avatar}` : ''} className={classes.picture} >
                                        {props.user.nombre.charAt(0).toUpperCase() + (props.user.nombre.split(' ').length > 1 ? props.user.nombre.split(' ')[1].charAt(0).toUpperCase() : '') }
                                    </Avatar>
                                    </div>
                                    <div>
                                        <Typography variant="h6">{props.user.nombre}</Typography>
                                        <Typography>{props.user.usuario}</Typography>
                                    </div>
                                </div>
                                <List style={{ padding: 0 }}>
                                    {props.accounts.filter(f => f.nombreUsuario !== props.user.usuario).map((account, key) => 
                                        <ListItem className={classes.cardActions} button key={key} onClick={() => handleClickUser(account.authuser)}>
                                            <Avatar variant="square" src={account.avatar ? `data:image/jpeg;base64,${account.avatar}` : ''} className={classes.avatar}>
                                                {account.nombre.charAt(0).toUpperCase() + (account.nombre.split(" ").length > 1 ? account.nombre.split(" ")[1].charAt(0).toUpperCase() : '') }
                                            </Avatar>
                                            <ListItemText
                                                primary={<div className={classes.primaryText}>{account.nombre}</div>}
                                                secondary={<div className={classes.secondaryText}>{account.nombreUsuario}</div>}
                                            />
                                            <ListItemSecondaryAction>
                                                <div className={classes.secondaryText}>{account.autenticado ? '' : 'Sesión finalizada'}</div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                                <Button variant='outlined' className={classes.button} onClick={handleAddAccount} >Agregar Cuenta</Button>
                                <div className={classes.grow}></div>
                                <Button variant='outlined' className={classes.button} onClick={handleSignOut} >Salir</Button>
                            </CardActions>
                        </Card>
                       
                      
                    </Popover>

                </div>
                {props.piqueId > 0 && props.seconds <= 4*3600 && <div>{fnConvertSecondsToTime(4*60*60 - props.seconds)}</div>}

               
            </Toolbar>
        </AppBar>
    );

};

const mapStateToProps = state => ({
    accounts: state.session.accounts,
    user: state.session.user,
    ordenTrabajoId: state.workorder.ordenTrabajoId,
    showOrder: state.workorder.showOrder,
    seconds: state.user.seconds,
    piqueId: state.workorder.piqueId,
    parameter: state.workorder.parametro,
    show: state.workorder.show,
    showx: state.workorder.showx
    // order: state.order,
    // oferta:state.oferta,
    // open:state.user.open
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AppMenu);