import { IdentityUrl } from "../config";
import axios from "axios";
import { actionCreators as actionStation } from "./station";
import { actionCreators as actionWorkOrder } from "./workorder";
import {
  FETCH_USER_SUCCESS,
  LOAD_FINISHED,
  PRELOAD_FINISH,
  TOGGLE_SHOW_ORDER,
  INCREMENT_SECONDS,
  OPEN_DIALOG_PRODUCT,
  CLOSE_DIALOG_PRODUCT,
  OPEN_DIALOG_PRODUCT_OFERTA,
  CLOSE_DIALOG_PRODUCT_OFERTA,
} from "../constants/actionTypes";

const initialState = {
  color: "default",
  totalLoad: 0,
  name: "",
  username: "",
  seconds: 0,
  open: false,
  opens:false,
};

export const actionCreators = {
  getUser: () => async (dispatch) => {
    try {
      let url = `${IdentityUrl}/api/v1/account/getuser`;
      let response = await axios.get(url);
      let data = response.data;

      dispatch({
        type: FETCH_USER_SUCCESS,
        name: data.name,
        username: data.userName,
      });
      dispatch(actionStation.getAssignedStation());
    } catch (error) {
      console.log(`Error Usuario: ${error}`);
    }
  },

  toggleShowOrder: () => ({ type: TOGGLE_SHOW_ORDER }),
  incrementSeconds: (seconds) => (dispatch, getState) => {
    if (getState().workorder.piqueId === 0) {
      dispatch({ type: INCREMENT_SECONDS, stop: true });
      return;
    }

    if (getState().user.seconds + 1 > 4 * 60 * 60) {
      dispatch(actionWorkOrder.onUnassignStation());
    }

    dispatch({ type: INCREMENT_SECONDS, seconds });
  },

  onOpenProductDialog: () => ({ type: OPEN_DIALOG_PRODUCT }),
  onCloseProductDialog: () => ({ type: CLOSE_DIALOG_PRODUCT }),
  onOpenProductDialogOferta: () => ({ type: OPEN_DIALOG_PRODUCT_OFERTA }),
  onCloseProductDialogOferta: () => ({ type: CLOSE_DIALOG_PRODUCT_OFERTA }),
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        name: action.name,
        username: action.username,
      };
    case LOAD_FINISHED:
      return {
        ...state,
        totalLoad: state.totalLoad + 1,
      };
    case PRELOAD_FINISH:
      return {
        ...state,
        isLoaded: true,
      };
    case INCREMENT_SECONDS:
      return {
        ...state,
        seconds: action.stop
          ? initialState.seconds
          : state.seconds + (action.seconds || 1),
      };
    case CLOSE_DIALOG_PRODUCT:
      return {
        ...state,
        open: false,
      };
    case OPEN_DIALOG_PRODUCT:
      return {
        ...state,
        open: true,
      };
      case CLOSE_DIALOG_PRODUCT_OFERTA:
      return {
        ...state,
        opens: false,
      };
    case OPEN_DIALOG_PRODUCT_OFERTA:
      return {
        ...state,
        opens: true,
      };
    default:
      return state;
  }
};
