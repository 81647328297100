import {
    CLOSE_MESSAGE_DIALOG,
    CONFIRM_MESSAGE_DIALOG,
    ERROR_MESSAGE_DIALOG,
    OPEN_MESSAGE_DIALOG,
    QUESTION_MESSAGE_DIALOG
} from '../constants/actionTypes';

const initialState = {
    isConfirm: false,
    isError: false,
    isLoading: false,
    isQuestion: false,
    message: '',
    open: false
};

export const actionCreators = {
    onCloseMessageDialog: () => ({ type: CLOSE_MESSAGE_DIALOG })
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case CLOSE_MESSAGE_DIALOG:
            return {
                ...initialState,
            };
        case CONFIRM_MESSAGE_DIALOG:
            return {
                ...initialState,
                isConfirm: true,
                message: action.message,
                open: true
            };
        case ERROR_MESSAGE_DIALOG:
            return {
                ...initialState,
                isError: true,
                message: action.message,
                open: true
            }
        case OPEN_MESSAGE_DIALOG:
            return {
                ...initialState,
                isLoading: true,
                message: action.message,
                open: true
            };
        case QUESTION_MESSAGE_DIALOG:
            return {
                ...initialState,
                isQuestion: true,
                message: action.message,
                open: true
            };
        default:
            return state;
    }

};