import React, { useEffect } from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/oil';

const Oil = props => {
    const oil = props.oil;
    const ranking = props.oil.ranking;
    // console.log('Oil' + JSON.stringify(oil))
    // console.log('Ranking' + JSON.stringify(ranking))

    useEffect(() => {
        props.requestOils();
    // eslint-disable-next-line
    }, []);

    function handleNext() {
        props.incrementRanking();
        props.requestOils();
    }

    function handleBack() {
        props.decrementRanking();
        props.requestOils();
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <BottomNavigation
                value={oil.codSubCategoria}
                onChange={props.changeNav}
                showLabels
            >
                <BottomNavigationAction label="SINTÉTICO" value="S" disabled={oil.isLoading}/>
                <BottomNavigationAction label="SEMI SINTÉTICO" value="SS" disabled={oil.isLoading} />
                <BottomNavigationAction label="MINERAL" value="M" disabled={oil.isLoading} />
            </BottomNavigation>
            <div>
                {oil.isLoading && (<div style={{ textAlign: "center", minHeight: 335 }}>
                    <CircularProgress color="secondary" />
                </div>)}
                {!oil.isLoading && (<div><div style={{ textAlign: "right" }}>
                    <div>
                    {oil.codSubCategoria == "SS" && (<span style={{ margin: "0 25px", fontWeight: "bold", backgroundColor: "#14e014", padding: "15px"}}>
                        (*) Producto Sint&eacute;tico disponible en la marca Castrol
                        </span>)}
                    {oil.codSubCategoria == "M" && (<span style={{ margin: "0 25px", fontWeight: "bold", backgroundColor: "#14e014", padding: "15px"}}>
                        (*) Producto Semisint&eacute;tico disponible en la marca Castrol
                        </span>)}
                    <Fab color="secondary" onClick={props.addQuarterProduct} >
                        <Icon className="fas fa-plus" style={{ fontSize: 34 }}/>
                    </Fab>
                    </div>
                </div>
                <Grid container justify="flex-start" spacing={2} style={{ minHeight: 350 }}>
                    <Grid item>
                        <Grid container justify="flex-end" spacing={2}>
                            <Grid item >
                                <Paper className="price-paper" style={{ backgroundColor: "white", boxShadow: "none" }} >
                                    <Typography style={{fontSize: 18, fontWeight: 600 }}>S/</Typography>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper className="points-paper" style={{ backgroundColor: "white", boxShadow: "none" }} />
                            </Grid>
                        </Grid>
                        {/* Aqui renderiza todo el oil */}
                        {oil.oils.filter(function(e) { return e.ranking === 1 }).map((item, key) => (
                            <Grid key={key} container justify="flex-end" spacing={2}>
                                <Grid  item>
                                    <Paper style={{ textAlign:"left", boxShadow: "none", paddingTop: 16 }}>
                                        <Typography style={{fontSize: (26 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.dViscosidad}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                        <IconButton onClick={() => {
                                            if(item.stock >= item.cantidadAgregar){
                                                let detail = {
                                                    productoId: item.productoId,
                                                    dProducto: item.dProducto,
                                                    codigoCategoria: item.codigoCategoria,
                                                    cantidad: item.cantidadAgregar,
                                                    precioVenta: item.precioVenta/item.cantidadAgregar,
                                                    puntos: item.puntosAgregar/item.cantidadAgregar,
                                                    stock: item.stock - item.cantidadAgregar
                                                };
                                                props.addDetailProduct(detail);
                                            }
                                        }}>
                                            <img style={{ height: (36 - ranking*4), width: (36 - ranking*4) }} src={item.imagenMarca} alt={item.dViscosidad}/>
                                        </IconButton>
                                    </Paper>
                                </Grid>
                                {!item.invisible && (<Grid style={{ marginTop: 16 }}  item>
                                    <Paper className="price-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f5f538" }} >
                                        <Typography style={{fontSize: (22 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.precioVenta.toFixed(2)}</Typography>
                                    </Paper>
                                </Grid>)}
                                {!item.invisible && (<Grid style={{ marginTop: 16 }} item>
                                    <Paper className="points-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f00", color:"#fff" }} >
                                        <Typography style={{fontSize: (22 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.puntos}</Typography>
                                    </Paper>
                                </Grid>)}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item>
                        {oil.ranking >= 1 && (<Grid container justify="flex-end" spacing={2}>
                            <Grid item >
                                <Paper className="price-paper" style={{ backgroundColor: "white", boxShadow: "none" }} >
                                    <Typography style={{fontSize: 18, fontWeight: 600 }}>S/</Typography>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper className="points-paper" style={{ backgroundColor: "white", boxShadow: "none" }} />
                            </Grid>
                        </Grid>)}
                        {oil.oils.filter(function(e) { return e.ranking === 2 }).map((item, key) => (
                            <Grid key={key} container justify="flex-end" spacing={2}>
                                <Grid  item>
                                    <Paper style={{ textAlign:"right", boxShadow: "none", paddingTop: 16 }}>
                                        <Typography style={{fontSize: (26 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.dViscosidad}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                        <IconButton onClick={() => {
                                            if(item.stock >= item.cantidadAgregar){
                                                let detail = {
                                                    productoId: item.productoId,
                                                    dProducto: item.dProducto,
                                                    codigoCategoria: item.codigoCategoria,
                                                    cantidad: item.cantidadAgregar,
                                                    precioVenta: item.precioVenta/item.cantidadAgregar,
                                                    puntos: item.puntosAgregar/item.cantidadAgregar,
                                                    stock: item.stock - item.cantidadAgregar
                                                };
                                                props.addDetailProduct(detail);
                                            }
                                        }}>
                                            <img style={{ height: (36 - ranking*4), width: (36 - ranking*4) }} src={item.imagenMarca} alt={item.dViscosidad}/>
                                        </IconButton>
                                    </Paper>
                                </Grid>
                                {!item.invisible && (<Grid style={{ marginTop: 16 }}  item>
                                    <Paper className="price-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f5f538" }} >
                                        <Typography style={{fontSize: (22 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.precioVenta.toFixed(2)}</Typography>
                                    </Paper>
                                </Grid>)}
                                {!item.invisible && (<Grid style={{ marginTop: 16 }} item>
                                    <Paper className="points-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f00", color:"#fff" }} >
                                        <Typography style={{fontSize: (22 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.puntos}</Typography>
                                    </Paper>
                                </Grid>)}
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item>
                        {oil.ranking >= 2 && (<Grid container justify="flex-end" spacing={2}>
                            <Grid item >
                                <Paper className="price-paper" style={{ backgroundColor: "white", boxShadow: "none" }} >
                                    <Typography style={{fontSize: 18, fontWeight: 600 }}>S/</Typography>
                                </Paper>
                            </Grid>
                            <Grid item >
                                <Paper className="points-paper" style={{ backgroundColor: "white", boxShadow: "none" }} />
                            </Grid>
                        </Grid>)}
                        {oil.oils.filter(function(e) { return e.ranking === 3 }).map((item, key) => (
                            <Grid key={key} container justify="flex-end" spacing={2}>
                                <Grid  item>
                                    <Paper style={{ textAlign:"right", boxShadow: "none", paddingTop: 16 }}>
                                        <Typography style={{fontSize: (26 - ranking*5), fontWeight: 600, lineHeight: 1 }}>{item.dViscosidad}</Typography>
                                    </Paper>
                                </Grid>
                                <Grid item>
                                    <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                        <IconButton onClick={() => {
                                            if(item.stock >= item.cantidadAgregar){
                                                let detail = {
                                                    productoId: item.productoId,
                                                    dProducto: item.dProducto,
                                                    codigoCategoria: item.codigoCategoria,
                                                    cantidad: item.cantidadAgregar,
                                                    precioVenta: item.precioVenta/item.cantidadAgregar,
                                                    puntos: item.puntosAgregar/item.cantidadAgregar,
                                                    stock: item.stock - item.cantidadAgregar
                                                };
                                                props.addDetailProduct(detail);
                                            }
                                        }}>
                                            <img style={{ height: (36 - ranking*4), width: (36 - ranking*4) }} src={item.imagenMarca} alt={item.dViscosidad}/>
                                        </IconButton>
                                    </Paper>
                                </Grid>
                                {!item.invisible && (<Grid style={{ marginTop: 16 }}  item>
                                    <Paper className="price-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f5f538" }} >
                                        <Typography style={{fontSize: (22 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.precioVenta.toFixed(2)}</Typography>
                                    </Paper>
                                </Grid>)}
                                {!item.invisible && (<Grid style={{ marginTop: 16 }} item>
                                    <Paper className="points-paper" style={item.stock <= 0 ? { backgroundColor: "white", color:"#fff",  boxShadow: "none" } : { backgroundColor: "#f00", color:"#fff" }} >
                                        <Typography style={{fontSize: (22 - ranking*4), fontWeight: 600, lineHeight: 1 }}>{item.puntos}</Typography>
                                    </Paper>
                                </Grid>)}
                            </Grid>
                        ))}
                    </Grid>
                </Grid></div>)}
                <MobileStepper style={{backgroundColor: "#fff"}}
                    steps={3}
                    position="static"
                    variant="text"
                    activeStep={oil.ranking}    
                    nextButton={
                        <Fab size="large" color="secondary" onClick={handleNext} disabled={oil.isLoading || oil.ranking === 2} >
                            <Icon className="fas fa-arrow-right" style={{ fontSize: 34 }}/>
                        </Fab>
                    }
                    backButton={
                        <Fab size="large"  color="secondary" onClick={handleBack} disabled={oil.isLoading || oil.ranking === 0} >
                            <Icon className="fas fa-arrow-left" style={{ fontSize: 34 }}/>
                        </Fab>
                    }
                    >
                </MobileStepper>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    oil: state.oil
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Oil);