import { config } from 'dotenv';

config({
    path: `../.env.${process.env.NODE_ENV}`
});

export const ClientId = process.env.REACT_APP_CLIENT_ID;
export const IdentityUrl = process.env.REACT_APP_IDENTITY_URL;
export const RedirectPath = process.env.REACT_APP_REDIRECT_PATH;
export const CustomerApiUrl = process.env.REACT_APP_CUSTOMER_API_URL;
export const OperationsApiUrl = process.env.REACT_APP_OPERATIONS_API_URL;
export const ProductApiUrl = process.env.REACT_APP_PRODUCT_API_URL;
export const SalesApiUrl = process.env.REACT_APP_SALES_API_URL;
export const ArduinoApiUrl = process.env.REACT_APP_ARDUINO_API_URL;