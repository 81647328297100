import React, { useEffect }  from 'react';


import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/filter';

const useStyles = makeStyles(() => ({
    text: {
        backgroundColor: "#fff",
        borderColor: "#585454",
        borderStyle: "solid",
        borderWidth: 1,
        flexGrow: 1,
        fontSize: 16,
        fontWeight: 600,
        height: 32,
        paddingLeft: 4
    }
}));

const Filter = props => {
    const classes = useStyles();
    const filter = props.filter;

    useEffect(() => {
        props.requestFilters();
    // eslint-disable-next-line
    }, []);

    function onFieldChange(event) {
        props.onUpdateField(event.target.name, event.target.value);
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }} >
            <BottomNavigation
                value={filter.codSubCategoria}
                onChange={props.changeNav}
                showLabels
            >
                <BottomNavigationAction label="ACEITE" value="FAC" disabled={filter.isLoading}/>
                <BottomNavigationAction label="AIRE" value="FAI" disabled={filter.isLoading} />
                <BottomNavigationAction label="CABINA" value="FCA" disabled={filter.isLoading} />
            </BottomNavigation>
            <div>
                {filter.isLoading && (<div style={{ textAlign: "center" }}>
                    <CircularProgress color="secondary" />
                </div>)}
                {!filter.isLoading && (<Grid container justify="center" spacing={2} style={{ minHeight: 350 }}>
                    {filter.filters.map((item, key) => (
                        <Grid key={key} item>
                            <Grid direction="column" container justify="center" spacing={2}>
                                <Grid style={{ textAlign: "-webkit-right" }}  item>
                                    <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                        <img style={{ height: 375, width: 400 }} src={item.imagenUri} alt={item.productoId}/>
                                    </Paper>
                                    {item.puntos > 0 && (<Paper className="points-paper" style={item.stock <= 0 ? { backgroundColor: "gray" } : { backgroundColor: "#f00", color:"#fff" }} >
                                        <Typography style={{fontSize: 24, fontWeight: 600, lineHeight: 1 }}>{item.puntos}</Typography>
                                    </Paper>)}
                                </Grid>
                                
                                <Grid style={{ textAlign: "-webkit-right", padding: 0 }} item>
                                    <Paper style={{ boxShadow: "none" }}>
                                        <InputBase
                                                name="codigo"
                                                autoComplete="off"
                                                className={classes.text}
                                                value={filter.codigo}
                                                onChange={onFieldChange}
                                                style= {{ width: 100 }}
                                        />
                                        <IconButton onClick={props.findProduct} >
                                            <Icon className="fas fa-cart-plus" style={{ fontSize: 24 }} />
                                        </IconButton>
                                    </Paper>
                            </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>)}
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    filter: state.filter
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Filter);