import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent, Card, Switch, FormControlLabel } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/workorder';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    panel: {
        backgroundColor: "#efb001",
        borderRadius: 4,
        margin: "4px 2px 2px 4px",
        padding: 4,
        textAlign: "right"
    },
    text: {
        fontSize: 9,
        '@media (min-width: 800px)': {
            fontSize: 12
        },
        '@media (min-width: 1000px)': {
            fontSize: 14
        },
        '@media (min-width: 1200px)': {
            fontSize: 16
        },
        '@media (min-width: 1400px)': {
            fontSize: 18
        },
        '@media (min-width: 1600px)': {
            fontSize: 21
        }
    }
}));

const Sticker = props => {
    const classes = useStyles();

    function handleChange(event) {
        props.onUpdateFieldSticker(event.target.name, event.target.value);
        props.onRequestSticker();
    }

    return (
        <Card className={classes.panel}>
            <CardContent className={classes.content}>
            <FormControlLabel
            classes={{ label: classes.text }}
                value={props.solicitaSticker}
                control={<Switch
                    checked={props.solicitaSticker}
                    color="secondary"
                    name="solicitaSticker"
                    onChange={handleChange}
                />}
                label="Sticker de Seguridad"
                labelPlacement="start"
            />
            </CardContent>
        </Card>
    );
};

const mapStateToProps = state => ({
    solicitaSticker: state.workorder.solicitaSticker
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Sticker);