import React from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/dialog';

const MessageDialog = props => {
    const dialog = props.dialog;

    return (
        <Dialog aria-labelledby="loading-dialog-title" open={dialog.open} >
            <DialogTitle id="loading-dialog-title" style={{padding: 8}}>SIGECO OKEY</DialogTitle>
            <DialogContent style={{textAlign: 'center'}} dividers>
                <div style={{width: 250}} ></div>
                {dialog.isLoading && (<CircularProgress />)}
                <Typography style={{fontSize: 14}} color={dialog.isError ? "secondary" : "inherit"}>{dialog.message}</Typography>
            </DialogContent>
            {(dialog.isError || dialog.isConfirm) && (<DialogActions>
                <Button  size="small" onClick={props.onCloseMessageDialog}>
                    Aceptar
                </Button>
            </DialogActions>)}
            {dialog.isQuestion && (<DialogActions>
                <Button size="small" onClick={props.action}>
                    Sí
                </Button>
                <Button size="small" color="secondary" onClick={props.onCloseMessageDialog}>
                    No
                </Button>
            </DialogActions>)}
        </Dialog>
    )
}

const mapStateToProps = state => ({
    dialog: state.dialog
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(MessageDialog);