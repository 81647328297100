import React  from 'react';
import clsx from 'clsx';
import { useDispatch} from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators as actionCreatorsUser} from '../reducers/user';
import { actionCreators as actionCreatorsOferta} from '../reducers/oferta';

import {   
    CLOSE_DIALOG_PRODUCT_OFERTA
} from '../constants/actionTypes';

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: 27
    },
    table: {
        minWidth: 400
    },
    tableRow: {
        fontSize: 18,
        fontWeight: 600
    }
}));

const ProductsDialogOferta = props => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const oferta = props.oferta;


    function onHandleClick(productoId, cantidad) {
        props.onModifyProductoOT(productoId, cantidad);
    }

    function handleCloseOferta(){
        dispatch({type: CLOSE_DIALOG_PRODUCT_OFERTA});
    }

    function handleItemOferta(detail){
        dispatch(actionCreatorsOferta.addDetailProduct(detail));
        dispatch({type: CLOSE_DIALOG_PRODUCT_OFERTA});
    }
    return (
        <Dialog aria-labelledby="product-dialog-title" open={oferta.open} maxWidth="md" >
            <DialogContent >
                <Table className={classes.table} size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRow}>PRODUCTOS</TableCell>
                            <TableCell className={classes.tableRow}>MARCA</TableCell>
                            <TableCell className={classes.tableRow}>TIPO</TableCell>
                            <TableCell className={classes.tableRow}>CAT</TableCell>
                            <TableCell className={classes.tableRow}>PRECIO</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>    
                    <TableBody>
                        {oferta.details.map((item) => (<TableRow key={item.id}>
                            <TableCell className={classes.tableRow}>{item.dProducto}</TableCell>
                            <TableCell className={classes.tableRow}>
                            
                            <Grid item>
                                    <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                        <IconButton onClick={() => {
                                            if(item.stock >= item.cantidadAgregar){
                                                let detail = {
                                                    productoId: item.productoId,
                                                    dProducto: item.dProducto,
                                                    cantidad: item.cantidadAgregar,
                                                    precioVenta: item.precioVenta/item.cantidadAgregar,
                                                    puntos: item.puntosAgregar/item.cantidadAgregar,
                                                    stock: item.stock - item.cantidadAgregar
                                                };
                                                handleItemOferta(detail);
                                            }
                                        }}>
                                            <img style={{ height: 30, width: 30 }} src={item.imagenMarca} alt={item.dViscosidad}/>
                                        </IconButton>
                                    </Paper>
                            </Grid>
                            </TableCell>
                            <TableCell className={classes.tableRow}>{item.combustible}</TableCell> 
                            <TableCell className={classes.tableRow}>{item.codigoCategoria}</TableCell> 
                            <TableCell className={classes.tableRow}>{item.precioVenta*item.cantidadAgregar}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=> handleCloseOferta(e)} >CERRAR</Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    user:state.user,
    oferta:state.oferta,
});



export default connect(
    mapStateToProps,
    dispatch => bindActionCreators([actionCreatorsUser, actionCreatorsOferta], dispatch)
)(ProductsDialogOferta);