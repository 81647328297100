import {
    ADD_DETAIL_PRODUCT,
    CHANGE_TAB_INDEX,
    CLOSE_DIALOG_PRODUCT,
    ERROR_MESSAGE_DIALOG,
    OPEN_DIALOG_PRODUCT,
    UPDATE_STATE_ORDER,
    RESTART_STATE_ORDER
} from '../constants/actionTypes';

const initialState = {
    tabIndex: 0,
    details: [],
    open: false,
    isSent: true
};


export const actionCreators = {
    onChangeTab: (_e, value) => ({ type: CHANGE_TAB_INDEX, value }),

    onModifyProductoOT: (productoId, cantidad) => async(dispatch, getState) => {
        let details = [...getState().order.details];
        let index = details.findIndex(function(item){
            return item.productoId === productoId;
        });

        if(index >= 0){
            if(details[index].cantidad + cantidad === 0) {
                //si se quita aditivo, se quita el servicio con precio especial
                if(details[index].productoId === 294) {
                    let indexP = details.findIndex(function(item){
                        return item.productoId === 394;
                    });
                    if(indexP >= 0) {
                        details[indexP].precioVenta = 45.00;
                    }
                }
                //
                details.splice( index, 1 );
                dispatch({ type: ADD_DETAIL_PRODUCT, details });
            }
            else {
                if(details[index].stock - cantidad < 0) {
                    dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'El código ingresado no cuenta con stock disponible.' });
                    return;
                }

                details[index].cantidad += cantidad;
                details[index].stock -= cantidad;
                dispatch({ type: ADD_DETAIL_PRODUCT, details });
            }
            dispatch({ type: UPDATE_STATE_ORDER });
        }
    },
    
    onOpenProductDialog: () => ({ type: OPEN_DIALOG_PRODUCT }),
    onCloseProductDialog: () => ({ type: CLOSE_DIALOG_PRODUCT }),
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case ADD_DETAIL_PRODUCT:
            return Object.assign({}, state, {
                details: action.details
            });
        case CHANGE_TAB_INDEX:
            return {
                ...state,
                tabIndex: action.value
            };
        case CLOSE_DIALOG_PRODUCT:
            return{
                ...state,
                open: false
            };
        case OPEN_DIALOG_PRODUCT:
            return{
                ...state,
                open: true
            };
        case UPDATE_STATE_ORDER:
            return {
                ...state,
                isSent: action.estado | false
            }
        case RESTART_STATE_ORDER:
            return initialState;
        default:
            return state;
        
    }

};