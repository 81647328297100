import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/order';

const useStyles = makeStyles(() => ({
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    grow: {
        flexGrow: 1
    },
    grupo: {
        color: "f00",
        display: "flex", 
        flexDirection: "row"
    },
    header: {
        padding: 2
    },
    panel: {
        backgroundColor: "#efb001",
        borderRadius: 4,
        margin: "4px 4px 2px 2px",
        padding: 4,
        textAlign: "center"
    },
    puntos: {
        color: "#f00",
        fontSize: 16,
        fontWeight: 600,
        '@media (min-width: 800px)': {
            fontSize: 20
        },
        '@media (min-width: 1000px)': {
            fontSize: 24
        },
        '@media (min-width: 1200px)': {
            fontSize: 28
        },
        '@media (min-width: 1400px)': {
            fontSize: 32
        },
        '@media (min-width: 1600px)': {
            fontSize: 36
        }
    },
    subtitle: {
        fontSize: 9,
        fontWeight: 600,
        '@media (min-width: 800px)': {
            fontSize: 10
        },
        '@media (min-width: 1000px)': {
            fontSize: 12
        },
        '@media (min-width: 1200px)': {
            fontSize: 14
        },
        '@media (min-width: 1400px)': {
            fontSize: 16
        },
        '@media (min-width: 1600px)': {
            fontSize: 18
        }
    },
    total: {
        fontSize: 24,
        fontWeight: 600,
        '@media (min-width: 800px)': {
            fontSize: 28
        },
        '@media (min-width: 1000px)': {
            fontSize: 32
        },
        '@media (min-width: 1200px)': {
            fontSize: 36
        },
        '@media (min-width: 1400px)': {
            fontSize: 40
        },
        '@media (min-width: 1600px)': {
            fontSize: 44
        }
    }
}));

const Total = props => {
    const classes = useStyles();
    const order = props.order;
    const totalPuntos = order.details.length > 0 ? order.details.map(item => item.cantidad*item.puntos).reduce((prev, next) => prev + next) : 0;
    const totalDetalle = order.details.length > 0 ? order.details.map(item => item.cantidad*item.precioVenta).reduce((prev, next) => prev + next) : 0;
    
    return (
        <Card className={classes.panel}>
            <CardHeader className={classes.header} title="TOTAL"/>
            <CardContent className={classes.content}>
                <Typography className={classes.total}>S/ {totalDetalle.toFixed(2)}</Typography>
                <div className={classes.grupo}>
                    <div className={classes.grow}>
                        <Typography className={classes.subtitle}>PTOS ACUM</Typography>
                        <Typography className={classes.puntos}>{props.puntos}</Typography>
                    </div>
                    <div className={classes.grow}>
                        <Typography className={classes.subtitle}>PTOS GANADOS</Typography>
                        <Typography className={classes.puntos}>{totalPuntos}</Typography>
                    </div>
                </div>
            </CardContent>
        </Card>
    );
};

const mapStateToProps = state => ({
    order: state.order,
    puntos: state.client.vehiculo.puntos
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Total);