import axios from 'axios';
import { ClientId, IdentityUrl, RedirectPath } from '../config';
import { actionCreators as actionProcess } from './process'
import {
    LOAD_FINISHED,
    RECEIVE_SESSION,
    REQUEST_SESSION,
    SET_ACCOUNTS,
    SET_DATA_USER
} from '../constants/actionTypes';

const initialState = {
    accessToken: '',
    expiresIn: '',
    tokenType: '',
    isBusy: false,
    user: {
        usuarioId: '',
        usuario: '',
        nombre: '',
        avatar: ''
    },
    accounts: []
};

const createInterceptor = () => (dispatch) => {
    axios.interceptors.request.use(
        config => {
            if (window.SigecoAuth.access_token && !config.headers.Authorization) {
                config.headers.Authorization = `${window.SigecoAuth.token_type} ${window.SigecoAuth.access_token}`;
            }
            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            if(error.response && error.response.status === 401) {
                dispatch(getSession());
                error.response.data = {
                    eventLogId: 0,
                    message: 'Su token a caducado, reintente por favor.'
                };
            }
            return Promise.reject(error);
        }
    );
};

const getSession = () => async (dispatch, getState) => {
    if(getState().session.isBusy) {
        return;
    }
    
    let urlParams = new URLSearchParams(window.location.search);
    let providerUri = `${IdentityUrl}/authorize`
        + `?client_id=${ClientId}`
        + `&response_type=token`
        + `&grant_type=authorization_code`
        + `&redirect_uri=${window.location.protocol}//${window.location.host}/${RedirectPath}`
        + `&authuser=${urlParams.get('authuser') || '0'}`
        + (urlParams.get('company_code') == null ? '' : `&company_code=${urlParams.get('company_code')}`);
    let iframeId = 'silent-authorize-iframe';
    let old_iframe = document.getElementById(iframeId);

    dispatch({ type: REQUEST_SESSION });
    if (old_iframe){
        document.body.removeChild(old_iframe);
    }
    //crear iframe
    const iframe = document.createElement('iframe');
    iframe.id = iframeId;
    iframe.className = 'silent-iframe';
    iframe.src = providerUri;
    document.body.appendChild(iframe);

    let listener = (event) => {
        let data = event.data;
        
        if (data.token_type) {
            dispatch({ type: RECEIVE_SESSION, accessToken: data.access_token, expiresIn: data.expires_in, tokenType: data.token_type });
            //Asignamos SigecoAuth
            window.SigecoAuth = {};
            window.SigecoAuth.access_token = data.access_token;
            window.SigecoAuth.token_type = data.token_type;
            window.SigecoAuth.identityUri = IdentityUrl;
            //
            setTimeout(() => {
                dispatch(getSession());
            }, (parseInt(data.expires_in, 10) - 30)*1000);
            window.removeEventListener('message', listener);
            document.body.removeChild(iframe);
            //
            if(getState().user.totalLoad === 0) {
                dispatch(getSesionData());
                dispatch(actionProcess.getActivities(1));
            }
        }
        else {
            if(data.error) {
                console.log(data.error);
                if(data.status === 401) {
                    window.location.href = `${IdentityUrl}/oauth/v2/?continue=${encodeURIComponent(window.location.href)}`;
                }
            }
        }
    }
    window.addEventListener('message', listener);
};

const getSesionData = () => async (dispatch) => {
    try {
        let url = `${IdentityUrl}/api/v1/account/datos`;
        let response = await axios.get(url);

        let data = response.data;

        dispatch({ type: SET_DATA_USER, usuarioId: data.usuario.usuarioId, usuario: data.usuario.nombreUsuario, nombre: data.usuario.nombre, avatar: data.usuario.avatar });
        dispatch({ type: LOAD_FINISHED });
        dispatch(onRequestAccounts());
    } catch (error) {
        console.log(`Error Usuario: ${error}`);
    }
};

const onRequestAccounts = () => async (dispatch) => {
    try {
        let response = await fetch(`${IdentityUrl}/signin/accounts`, { method: 'GET', credentials: 'include'});
        let data = await response.json();

        dispatch({ type: SET_ACCOUNTS, payload: data });
    } catch (error) {
        console.log(`Error Cuentas: ${error}`);
    }
};

export const actionCreators = {
    createInterceptor,
    getSession,
    getSesionData,
    onRequestAccounts
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case RECEIVE_SESSION:
            return {
                ...state,
                accessToken: action.accessToken,
                expiresIn: action.expiresIn,
                tokenType: action.tokenType,
                isBusy: false
            };
        case REQUEST_SESSION:
            return {
                ...state,
                isBusy: true
            };
        case SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            };
        case SET_DATA_USER:
            return {
                ...state,
                user: {
                    usuarioId: action.usuarioId,
                    usuario: action.usuario,
                    nombre: action.nombre,
                    avatar: action.avatar
                }
            };
        default:
            return state;
    }

};


