import { ProductApiUrl } from '../config';
import axios from 'axios';
import {
    ADD_DETAIL_PRODUCT,
    DECREMENT_RANKING_ADDITIVE,
    ERROR_MESSAGE_DIALOG,
    INCREMENT_RANKING_ADDITIVE,
    RECEIVE_ADDITIVE_LIST,
    REQUEST_ADDITIVE_LIST,
    UPDATE_STATE_ORDER,
   // TOGGLE_SHOWX
} from '../constants/actionTypes';

const initialState = {
    isLoading: false,
    ranking: 0,
    additives: []
};

export const actionCreators = {
    decrementRanking: () => ({ type: DECREMENT_RANKING_ADDITIVE }),
    incrementRanking: () => ({ type: INCREMENT_RANKING_ADDITIVE }),

    requestAdditives: () => async (dispatch, getState) => {
        if(getState().additive.isLoading) {
            return;
        }
        let ranking = getState().additive.ranking + 1;
        dispatch({ type: REQUEST_ADDITIVE_LIST });

        var r = getState().workorder.parametro ;
        if (r === ''){
           // dispatch({ type: TOGGLE_SHOWX});
        }
        let url = `${ProductApiUrl}/api/v1/producto/operador?categoriaId=3&codigoSubCategoria=SC005&ranking=${ranking}&tipo=${r}`;
        var response = await axios.get(url);
        var additives = response.data;

        dispatch({ type: RECEIVE_ADDITIVE_LIST, additives });
    },

    addDetailProduct: (detail) => (dispatch, getState) => {
        let details = [...getState().order.details];

        let index = details.findIndex(function(item){
            return item.productoId === detail.productoId;
        });

        if(index >= 0) {
            if(details[index].stock - detail.cantidad < 0) {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'El código ingresado no cuenta con stock disponible.' });
                return;
            }

            details[index].cantidad += detail.cantidad;
            details[index].stock -= detail.cantidad;
        }
        else {
            details.push(detail);
        }

        //actualizar servicio
        let indexA = details.findIndex(function(item){
            return item.productoId === 294;
        });

        if(indexA >= 0) {
            let indexS = details.findIndex(function(item){
                return item.productoId === 394;
            });

            if(indexS >= 0) {
                details[indexS].precioVenta = 15;
            }
        }
        //
        dispatch({ type: ADD_DETAIL_PRODUCT, details });
        dispatch({ type: UPDATE_STATE_ORDER });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case DECREMENT_RANKING_ADDITIVE:
            return {
                ...state,
                ranking: state.ranking - 1
            };
        case INCREMENT_RANKING_ADDITIVE:
            return {
                ...state,
                ranking: state.ranking + 1
            };
        case RECEIVE_ADDITIVE_LIST:
            return {
                ...state,
                isLoading: false,
                additives: action.additives
            };
        case REQUEST_ADDITIVE_LIST:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
        
    }

};