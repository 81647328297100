import { CustomerApiUrl, OperationsApiUrl, ProductApiUrl } from '../config';
import axios from 'axios';
import { actionCreators as filterAction } from './filter';
import {
    CHANGE_NAV_CLIENT,
    CLOSE_DIALOG_CLIENT,
    CLOSE_MESSAGE_DIALOG,
    ERROR_DIALOG_CLIENT,
    ERROR_MESSAGE_DIALOG,
    LOADING_DIALOG_CLIENT,
    OPEN_DIALOG_CLIENT,
    OPEN_MESSAGE_DIALOG,
    UPDATE_FIELD_CLIENT_POPUP,
    RECEIVE_CLIENT,
    RECEIVE_MARK_LIST,
    RECEIVE_MODELS_LIST,
    RESTART_STATE_CLIENT,
    UPDATE_DATA_CLIENT,
    UPDATE_DATA_VEHICLE,
    ADD_DETAIL_PRODUCT,
    UPDATE_STATE_ORDER,
    OPEN_DIALOG_STICKER,
    RECEIVE_CLIENT_SUNAT
} from '../constants/actionTypes';

const initialState = {
    open: false,
    error: '',
    isLoading: false,
    index: 0,
    showQuestion: false,
    cliente: {
        clienteId: 0,
        tipoDocumentoId: 3,
        tipoDocumentoClienteId: 1,
        tipoClienteId: 1,
        ruc: '',
        dni: '',
        dCliente: '',
        dTipoCliente: '',
        razonSocial: '',
        primerNombre: '',
        apellidoPaterno: '',
        domicilioFiscal: '',
        distrito: '',
        fin: ''
    },
    vehiculo: {
        vehiculoId: 0,
        marcaId: 0,
        modeloId: 0,
        placa: '',
        dMarca: '',
        dModelo: '',
        anio: '',
        motor: '',
        kilometraje: '',
        puntos: 0,
        creaModelo: false
    },
    marcas: [],
    modelos: []
};

export const actionCreators = {
    changeNav: (_e, value) => (dispatch) => {
        dispatch({ type: CHANGE_NAV_CLIENT, value });
    },
    onCloseDialog: () => ({ type: CLOSE_DIALOG_CLIENT }),
    onOpenClientDialog: () => ({ type: OPEN_DIALOG_CLIENT }),
    onUpdateField: (key, value) => ({ type: UPDATE_FIELD_CLIENT_POPUP, key, value }),
    onCreateClient: () => async (dispatch, getState) => {
        dispatch({ type: LOADING_DIALOG_CLIENT });

        let hasErrorClient = false;
        let hasErrorVehicle = false;
        let cliente = getState().client.cliente;
        let v = getState().client.vehiculo;

        let newCliente = {
            tipoClienteId: cliente.tipoClienteId,
            domicilioFiscal: (cliente.domicilioFiscal || '-').toUpperCase(),
            tipoPersona: cliente.tipoDocumentoId === 1 ? 'J' : 'N',
            tipoDocumentoId: cliente.tipoDocumentoClienteId,
            dni: cliente.dni,
            ruc: cliente.ruc,
            primerNombre: cliente.primerNombre.toUpperCase(),
            apellidoPaterno: cliente.apellidoPaterno.toUpperCase(),
            razonSocial: cliente.razonSocial.toUpperCase(),
            distrito: cliente.distrito.toUpperCase()
        };

        let newVehiculo = {
            marcaId: v.marcaId,
            placa: v.placa.toUpperCase(),
            modelo: v.dModelo,
            anio: v.anio,
            motor: v.motor 
        }
        try {
            let url = `${CustomerApiUrl}/api/v1/clientes?devuelveExiste=true`;
            let response = await axios.post(url, newCliente, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;

            dispatch({ 
                type: UPDATE_DATA_CLIENT, 
                clienteId: data.clienteId, 
                dCliente: cliente.tipoDocumentoId === 1 ? cliente.razonSocial : cliente.apellidoPaterno + ' ' + cliente.primerNombre,
                dTipoCliente: cliente.tipoClienteId === 1 ? 'PARTICULAR' : 'PROFESIONAL'
            });
        } catch (error) {
            hasErrorClient = true;
            dispatch({ type: ERROR_DIALOG_CLIENT, error: error.response.data.message.toString() });
        }

        try {
            if(getState().client.cliente.clienteId > 0) {
                newVehiculo.clienteId = getState().client.cliente.clienteId;
            }
            
            console.log(newVehiculo);
            let url = `${OperationsApiUrl}/api/v1/vehiculo?devuelveExiste=true&creaModelo=${getState().client.vehiculo.creaModelo}`;
            let response = await axios.post(url, newVehiculo, {headers: {'Content-Type': 'application/json'}});
            let data = response.data;
            
            dispatch({ 
                type: UPDATE_DATA_VEHICLE, 
                vehiculoId: data.vehiculoId,
                modeloId: data.modeloId
            });
        } catch (error) {
            hasErrorVehicle = true;
            let showQuestion = false;
            if(error.response.data.eventLogId === 0){
                showQuestion = true;
            }
            dispatch({ type: ERROR_DIALOG_CLIENT, error: error.response.data.message.toString(), showQuestion });
        }

        if(!hasErrorClient && !hasErrorVehicle) {
            dispatch({ type: CLOSE_DIALOG_CLIENT });
        }
    },
    onRequestMarks: () => async (dispatch, getState) => {
        if(getState().client.isLoading) {
            return;
        }

        var response = await axios.get(`${OperationsApiUrl}/api/v1/marca/listatodo`);
        var marcas = response.data;

        dispatch({ type: RECEIVE_MARK_LIST, marcas });

    },
    onRequestModels: (marcaId) => async (dispatch, getState) => {
        if(getState().client.isLoading) {
            return;
        }

        var response = await axios.get(`${OperationsApiUrl}/api/v1/modelo?marcaId=${marcaId}`);
        var modelos = response.data;

        dispatch({ type: RECEIVE_MODELS_LIST, modelos });

    },
    onFindModel: () => async (dispatch, getState) => {
        dispatch({ type: LOADING_DIALOG_CLIENT });

        let marcaId = getState().client.vehiculo.marcaId;
        let dModelo = getState().client.vehiculo.dModelo;
        let anio = getState().client.vehiculo.anio;
        let motor = getState().client.vehiculo.motor;

        try {
            let url = `${OperationsApiUrl}/api/v1/modelo/buscar?marcaId=${marcaId}&dModelo=${dModelo}&anio=${anio}&motor=${motor}`;
            let response = await axios.get(url);
            let data = response.data;

            dispatch({ type: UPDATE_DATA_VEHICLE, modeloId: data.modeloId });
            dispatch({ type: CLOSE_DIALOG_CLIENT, cotiza: true });
            dispatch(filterAction.requestFilters());
            
        } catch (error) {
            dispatch({ type: ERROR_DIALOG_CLIENT, error: error.response.data.message.toString() });
        }
    },
    onGetClientByPlaque: () => async (dispatch, getState) => {
        let placa = getState().client.vehiculo.placa;

        if(placa.trim() === ''){
            return;
        }

        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Buscando información del cliente' });

        try {
            let url = `${OperationsApiUrl}/api/v1/vehiculo/placa?placa=${placa}`;
            let response = await axios.get(url);

            let modelos = [{ modeloId: response.data.modeloId, descripcion: response.data.modelo }];

            dispatch({ type: RECEIVE_MODELS_LIST, modelos });
            dispatch({ type: RECEIVE_CLIENT, data: response.data });
            dispatch({ type: CLOSE_MESSAGE_DIALOG });
        } catch (error) {
            let response = error.response;

            if(response.status === 404){
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'La placa ingresada no se encuentra en la base de datos.' });
            }
            else{
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: response.data.message.toString() });
            }
        }

        let vehiculoId = getState().client.vehiculo.vehiculoId;
        if( vehiculoId > 0 && getState().order.details.length === 0 ){
            try {
                let url = `${ProductApiUrl}/api/v1/producto/ultimaventa?vehiculoId=${vehiculoId}`;
                let response = await axios.get(url);
                let data = response.data;

                dispatch({ type: ADD_DETAIL_PRODUCT, details: data });
                dispatch({ type: UPDATE_STATE_ORDER });

                if(data[0]) {
                    dispatch({ type: UPDATE_FIELD_CLIENT_POPUP, key: 'kilometraje', value: data[0].kilometraje });
                }
            } catch (error) {
                console.log(error.response.data);
            }
        }
    },
    onBuscarPorSunat: () => async (dispatch, getState) => {
        let ruc = getState().client.cliente.ruc;

        if(ruc.trim() === ''){
            return;
        }
        dispatch({ type: LOADING_DIALOG_CLIENT });

        try {
            let url = `http://sigeco.biz/services/sunatapi/api/v1/operaciones/consultaruc/${ruc}`;
            let response = await axios.get(url);
            let data = response.data;

            if(data.ruc == null) {
                dispatch({ type: ERROR_DIALOG_CLIENT, error: 'No se encontro la información. Reintente!' });
            }
            else {
                let cliente = {
                    apellidoPaterno: data.apellidoPaterno,
                    dCliente: data.razonSocial,
                    razonSocial: data.razonSocial,
                    ruc: data.ruc,
                    domicilioFiscal: data.domicilioFiscal
                }
                dispatch({ type: RECEIVE_CLIENT_SUNAT, data: cliente });

                if(data.estado === 'ACTIVO') {
                    dispatch({ type: ERROR_DIALOG_CLIENT, error: '' });
                }
                else {
                    dispatch({ type: ERROR_DIALOG_CLIENT, error: 'El contribuyento no se encuentra activo.' });
                }
                
            }
        } catch (error) {
            if(error.response) {
                dispatch({ type: ERROR_DIALOG_CLIENT, error: error.response.data.message.toString() });
            }
            else {
                dispatch({ type: ERROR_DIALOG_CLIENT, error: 'Ocurrio un error conectando al servidor.' });
            }
        }
    },
    onOpenStickerDialog: () => ({ type: OPEN_DIALOG_STICKER })
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case CHANGE_NAV_CLIENT:
            return {
                ...state,
                index: action.value
            };
        case CLOSE_DIALOG_CLIENT:
            return{
                ...state,
                cliente: state.clienteId === 0 ? initialState.cliente : state.cliente,
                vehiculo: ((action.cotiza || false) ? state.vehiculo : (state.vehiculo.vehiculoId === 0 ? initialState.vehiculo : state.vehiculo)),
                isLoading: false,
                open: false,
                showQuestion: false
            };
        case OPEN_DIALOG_CLIENT:
            return{
                ...state,
                open: true
            }
        case UPDATE_FIELD_CLIENT_POPUP:
            return { 
                ...state,
                cliente: {
                    ...state.cliente,
                    [action.key]: action.value
                },
                vehiculo: {
                    ...state.vehiculo,
                    [action.key]: ( action.key === 'creaModelo' ? ( action.value === 'true' ? false : true ) : action.value )
                }
            };
        case RECEIVE_CLIENT:
            return {
                ...state,
                cliente: {
                    ...state.cliente,
                    clienteId: action.data.clienteId,
                    tipoDocumentoId: action.data.tipoDocumentoId,
                    tipoDocumentoClienteId: action.data.tipoDocumentoClienteId,
                    tipoClienteId: action.data.tipoClienteId,
                    ruc: action.data.ruc,
                    dni: action.data.dni,
                    dCliente: action.data.dCliente || '',
                    dTipoCliente: action.data.dTipoCliente || '',
                    razonSocial: action.data.razonSocial,
                    primerNombre: action.data.primerNombre,
                    apellidoPaterno: action.data.apellidoPaterno,
                    domicilioFiscal: action.data.domicilioFiscal || '',
                    distrito: action.data.distrito,
                    fin: action.data.fin || ''
                },
                vehiculo: {
                    ...state.vehiculo,
                    vehiculoId: action.data.vehiculoId,
                    marcaId: action.data.marcaId,
                    modeloId: action.data.modeloId,
                    placa: action.data.placa,
                    dMarca: action.data.marca,
                    dModelo: action.data.modelo,
                    anio: action.data.anio,
                    motor: action.data.motor,
                    puntos: action.data.puntos,
                    kilometraje: action.data.kilometraje || '',
                    creaModelo: false
                }
            }
        case RECEIVE_CLIENT_SUNAT:
            return {
                ...state,
                cliente: {
                    ...state.cliente,
                    apellidoPaterno: action.data.apellidoPaterno || '',
                    ruc: action.data.ruc,
                    dCliente: action.data.dCliente || '',
                    razonSocial: action.data.razonSocial || '',
                    domicilioFiscal: action.data.domicilioFiscal || ''
                }
            }
        case RECEIVE_MARK_LIST:
            return {
                ...state,
                isLoading: false,
                marcas: action.marcas
            }
        case RECEIVE_MODELS_LIST:
                return {
                    ...state,
                    isLoading: false,
                    modelos: action.modelos
                }
        case UPDATE_DATA_CLIENT:
            return {
                ...state,
                cliente: {
                    ...state.cliente,
                    clienteId: action.clienteId,
                    dCliente: action.dCliente,
                    dTipoCliente: action.dTipoCliente
                }
            }
        case UPDATE_DATA_VEHICLE:
            return {
                ...state,
                vehiculo: {
                    ...state.vehiculo,
                    vehiculoId: action.vehiculoId || state.vehiculo.vehiculoId,
                    modeloId: action.modeloId
                }
            }
        case ERROR_DIALOG_CLIENT:
            return {
                ...state,
                error: action.error,
                isLoading: false,
                showQuestion: action.showQuestion || false
            }
        case LOADING_DIALOG_CLIENT:
            return {
                ...state,
                error: '',
                isLoading: true
            }
        case RESTART_STATE_CLIENT:
            return {
                ...initialState,
                marcas: state.marcas
            }
        default:
            return state;
        
    }

};