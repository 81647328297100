import {
    INCREMENT_TIMER,
    START_TIMER,
    STOP_TIMER
} from '../constants/actionTypes';

const initialState = {
    active: false,
    seconds: 0
};

export const actionCreators = {
    incrementSeconds: () => (dispatch, getState) => {
        if(getState().timer.active){
            dispatch({ type: INCREMENT_TIMER });
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case INCREMENT_TIMER:
            return {
                ...state,
                seconds: state.seconds + 1
            };
        case START_TIMER:
            return {
                ...state,
                active: true,
                seconds: action.seconds || 0
            };
        case STOP_TIMER:
            return {
                ...state,
                active: false
            };
        default:
            return state;
    }
};