import { ArduinoApiUrl, OperationsApiUrl } from '../config';
import axios from 'axios';
import {
    CLOSE_MESSAGE_DIALOG,
    CLOSE_DIALOG_RATING,
    ERROR_MESSAGE_DIALOG,
    OPEN_MESSAGE_DIALOG,
    OPEN_DIALOG_RATING,
    RESTART_STATE,
    RESTART_STATE_CLIENT,
    RESTART_STATE_ORDER,
    SET_ACTIVITY_INDEX
} from '../constants/actionTypes';

const initialState = {
    open: false
};

export const actionCreators = {
    onCloseDialog: (value) => async (dispatch, getState) => {
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Enviando calificación, espere por favor' });

        let piqueId = getState().workorder.piqueId;
        let url = `${OperationsApiUrl}/api/v1/ordentrabajo/calificar`;
        let data = {
            ordenTrabajoId: getState().workorder.ordenTrabajoId,
            valor: value
        };

        try {
            await axios.put(url, data, {headers: {'Content-Type': 'application/json'}});

            dispatch({ type: CLOSE_DIALOG_RATING });
            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: RESTART_STATE });
            dispatch({ type: RESTART_STATE_ORDER });
            dispatch({ type: RESTART_STATE_CLIENT });
            dispatch({ type: SET_ACTIVITY_INDEX, index: 0 });

        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 

        //Luz y sonido
        try {
            let url = `${ArduinoApiUrl}/encender/pique/${piqueId}`;
            var response = await axios.get(url);
            console.log(response.data);
        } catch (error) {
            console.log(error);
        }

        try {
            document.getElementById('timbre').play();
        } catch (error) {
            console.log(error);
        }

        setTimeout(async function(){
            try {
                let url = `${ArduinoApiUrl}/apagar/pique/${piqueId}`;
                var response = await axios.get(url);
                console.log(response.data);
            } catch (error) {
                console.log(error);
            }
        }, 60000)

    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case CLOSE_DIALOG_RATING:
            return{
                ...state,
                open: false
            };
        case OPEN_DIALOG_RATING:
            return{
                ...state,
                open: true
            }
        default:
            return state;
        
    }

};