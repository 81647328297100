import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Stepper } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import IconActivity from "../icons/icon";
import Avatar from '@material-ui/core/Avatar';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/process';

const useStyles = makeStyles(() => ({
    avatar: {
        height: 33,
        width: 33,
        '@media (min-width: 800px)': {
            height: 43,
            width: 43
        },
        '@media (min-width: 1000px)': {
            height: 51,
            width: 51
        },
        '@media (min-width: 1200px)': {
            height: 60,
            width: 60
        },
        '@media (min-width: 1400px)': {
            height: 69,
            width: 69
        },
        '@media (min-width: 1600px)': {
            height: 78,
            width: 78
        }
    },
    icon: {
        height: 26,
        width: 26,
        '@media (min-width: 800px)': {
            height: 34,
            width: 34
        },
        '@media (min-width: 1000px)': {
            height: 41,
            width: 41
        },
        '@media (min-width: 1200px)': {
            height: 48,
            width: 48
        },
        '@media (min-width: 1400px)': {
            height: 55,
            width: 55
        },
        '@media (min-width: 1600px)': {
            height: 62,
            width: 62
        }
    },
    label: {
        color: '#0f0'
    },
    panel: {
        backgroundColor: '#fff',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        margin: "4px 2px",
        padding: 4,
        overflow: 'auto'
    },
    stepper: {
        flexGrow: 1,
        padding: "8px 0 0"
    }
}));

const Process = props => {
    const classes = useStyles();
    const process = props.process;
    const activity = props.process.activities[props.process.index];

    return (
        <div className={classes.panel}>
            {process.activities.filter((item, key) => { return key%6 === 0 }).map((item, key) => (
                <Stepper activeStep={parseInt(activity.orden/6,10) < key ? -1 : (parseInt(activity.orden/6,10) > key ? 6 : activity.orden%6) } key={key} className={classes.stepper} alternativeLabel>
                    {process.activities.filter((itemChild, keyChild) => { return key === parseInt(keyChild/6, 10) } ).map((itemChild, keyChild) => (
                        <Step key={itemChild.actividadId}>
                            <StepLabel className={classes.label} StepIconComponent={() => 
                                <Avatar className={classes.avatar} style={{backgroundColor: itemChild.orden < activity.orden ? "#33af33" : (itemChild.orden > activity.orden ? "#bdbdbd" : "#eeb02e")}}>
                                    <IconActivity className={classes.icon} color="#fff" name={itemChild.nombreImagen} />
                                </Avatar>
                                }>{itemChild.orden}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    process: state.process
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Process);