import React, { useEffect } from 'react';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/client';

const ClientDialog = props => {
    const actividad = props.actividad;
    const client = props.client;
    // const clienteId = client.cliente.clienteId;
    // const vehiculoId = client.vehiculo.vehiculoId;
    const iniciado = props.iniciado;

    useEffect(() => {
        props.onRequestMarks();
    // eslint-disable-next-line
    }, []);

    function onHandleSave() {
        props.onCreateClient();
    }

    function onHandleCancel() {
        props.onCloseDialog();
    }

    function onHandleSearchModel() {
        props.onFindModel();
    }

    function onFieldChange(event) {
        if(event.target.name === 'anio' && event.target.value.length > 4){
            return;
        }

        props.onUpdateField(event.target.name, event.target.value);
    }

    function onMarkFieldChange(event) {
        let dMarca = props.client.marcas.filter(function(e) { return e.marcaId === event.target.value })[0].descripcion
        
        props.onUpdateField(event.target.name, event.target.value);
        props.onUpdateField('dMarca', dMarca);
        props.onRequestModels(event.target.value);
    }

    function onModelFieldChange(event) {
        let dModelo = props.client.modelos.filter(function(e) { return e.modeloId === event.target.value })[0].descripcion
        
        props.onUpdateField(event.target.name, event.target.value);
        props.onUpdateField('dModelo', dModelo);
    }

    function onTxtClienteKeyPress(event) {
        if (event.key === 'Enter') {
            props.onBuscarPorSunat();
        }
    }

    function onTxtPlacaKeyPress(event) {
        if (event.key === 'Enter') {
            props.onGetClientByPlaque();
        }
    }

    return (
        <Dialog aria-labelledby="station-dialog-title" open={client.open} >
            <DialogContent >
            <BottomNavigation style={{ height: 54, marginBottom: 20 }}
                value={client.index}
                onChange={props.changeNav}
                showLabels
                >
                <BottomNavigationAction value={0} label="Cliente" icon={<Icon className="fas fa-user-circle" />} />
                <BottomNavigationAction value={1} label="Vehículo" icon={<Icon className="fas fa-car" />} />
            </BottomNavigation>
            {client.index === 0 && (<div style={{ width: 550, display: "flex", flexDirection: "column" }} >
                <FormControl disabled={client.isLoading}>
                    <InputLabel htmlFor="tipo-documento">TIPO DOCUMENTO VENTA</InputLabel>
                    <Select style={{ width: 250 }}
                    value={client.cliente.tipoDocumentoId}
                    onChange={onFieldChange}
                    inputProps={{
                        name: 'tipoDocumentoId',
                        id: 'tipo-documento',
                    }}
                    >
                    <MenuItem value={1}>FACTURA</MenuItem>
                    <MenuItem value={3}>BOLETA</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{display: (client.cliente.tipoDocumentoId === 1 ? "none" : "inline-flex"), marginTop: 8}} disabled={client.isLoading}>
                    <InputLabel htmlFor="tipo-documento">TIPO DOCUMENTO CLIENTE</InputLabel>
                    <Select style={{ width: 250 }}
                    value={client.cliente.tipoDocumentoClienteId}
                    onChange={onFieldChange}
                    inputProps={{
                        name: 'tipoDocumentoClienteId',
                        id: 'tipo-documento-cliente',
                    }}
                    >
                    <MenuItem value={1}>DNI</MenuItem>
                    <MenuItem value={4}>CARNET DE EXTRANJERIA</MenuItem>
                    <MenuItem value={7}>PASAPORTE</MenuItem>
                    </Select>
                </FormControl>
                <div style={{display: (client.cliente.tipoDocumentoId === 3 ? "none" : "inline-flex")}}>
                    <TextField style={{ width: 250 }}
                        margin="dense"
                        id="ruc"
                        name="ruc"
                        label="RUC"
                        value={client.cliente.ruc}
                        onChange={onFieldChange}
                        onKeyPress={onTxtClienteKeyPress}
                        hidden={client.cliente.tipoDocumentoId === 3}
                        disabled={client.isLoading}
                        inputProps={{
                            maxLength: 11
                        }}
                    />
                </div>
                <div style={{display: (client.cliente.tipoDocumentoId === 1 ? "none" : "inline-flex")}}>
                    <TextField style={{ width: 250 }}
                        margin="dense"
                        id="dni"
                        name="dni"
                        label="N° Documento"
                        value={client.cliente.dni}
                        onChange={onFieldChange}
                        disabled={client.isLoading}
                        inputProps={{
                            maxLength: 9
                        }}
                    />
                </div>
                <div style={{display: (client.cliente.tipoDocumentoId === 3 ? "none" : "inline-flex")}}>
                <TextField
                    margin="dense"
                    id="razonSocial"
                    name="razonSocial"
                    label="RAZÓN SOCIAL"
                    value={client.cliente.razonSocial}
                    onChange={onFieldChange}
                    disabled={client.isLoading}
                    fullWidth
                />
                </div>
                <div style={{display: (client.cliente.tipoDocumentoId === 1 ? "none" : "inline-flex")}}>
                <TextField style={{ width: 250 }}
                    margin="dense"
                    id="primer-nombre"
                    name="primerNombre"
                    label="PRIMER NOMBRE"
                    value={client.cliente.primerNombre}
                    onChange={onFieldChange}
                    disabled={client.isLoading}
                />
                </div>
                <div>
                <TextField style={{ width: 250 }}
                    margin="dense"
                    id="apellido-paterno"
                    name="apellidoPaterno"
                    label="APELLIDO PATERNO"
                    value={client.cliente.apellidoPaterno}
                    onChange={onFieldChange}
                    disabled={client.isLoading}
                />
                </div>
                <div style={{display: (client.cliente.tipoDocumentoId === 3 ? "none" : "inline-flex")}}>
                <TextField
                    margin="dense"
                    id="direccion"
                    name="domicilioFiscal"
                    label="DOMICILIO FISCAL"
                    value={client.cliente.domicilioFiscal}
                    onChange={onFieldChange}
                    disabled={client.isLoading}
                    fullWidth
                />
                </div>
                <div>
                <TextField style={{ width: 250 }}
                    margin="dense"
                    id="distrito"
                    name="distrito"
                    label="DISTRITO"
                    value={client.cliente.distrito}
                    onChange={onFieldChange}
                    disabled={client.isLoading}
                />
                </div>
                <FormControl style={{ marginTop: 8 }} disabled={client.isLoading}>
                    <InputLabel htmlFor="tipo-cliente">TIPO CLIENTE</InputLabel>
                    <Select style={{ width: 250 }}
                    value={client.cliente.tipoClienteId}
                    onChange={onFieldChange}
                    inputProps={{
                        name: 'tipoClienteId',
                        id: 'tipo-cliente',
                    }}
                    >
                    <MenuItem value={1}>PARTICULAR</MenuItem>
                    <MenuItem value={3}>PROFESIONAL</MenuItem>
                    </Select>
                </FormControl>
            </div>)}
            {client.index === 1 && (<div style={{ width: 550, display: "flex", flexDirection: "column" }} >
                <div>
                <TextField style={{ width: 145 }}
                    margin="dense"
                    id="placa-client"
                    name="placa"
                    label="PLACA"
                    value={client.vehiculo.placa}
                    onChange={onFieldChange}
                    onKeyPress={onTxtPlacaKeyPress}
                    disabled={client.isLoading}
                    inputProps={{
                        readOnly: actividad.orden > 8
                    }}
                />
                </div>
                <FormControl disabled={client.isLoading}>
                    <InputLabel htmlFor="marca">MARCA</InputLabel>
                    <Select style={{ width: 330 }}
                    value={client.vehiculo.marcaId}
                    onChange={onMarkFieldChange}
                    inputProps={{
                        name: 'marcaId',
                        id: 'marca',
                        readOnly: actividad.orden > 8
                    }}
                    >
                    {client.marcas.map((item, key) => (
                    <MenuItem key={key} value={item.marcaId}>{item.descripcion}</MenuItem>))}
                    </Select>
                </FormControl>
                <FormControl disabled={client.isLoading}>
                    <InputLabel htmlFor="modelo">MODELO</InputLabel>
                    <Select style={{ width: 330 }}
                    value={client.vehiculo.modeloId}
                    onChange={onModelFieldChange}
                    inputProps={{
                        name: 'modeloId',
                        id: 'modelo',
                        readOnly: actividad.orden > 8
                    }}
                    >
                    {client.modelos.map((item, key) => (
                    <MenuItem key={key} value={item.modeloId}>{item.descripcion}</MenuItem>))}
                    </Select>
                </FormControl>
                <div style={{ width: 145 }} >
                    <TextField
                        margin="dense"
                        id="anio"
                        name="anio"
                        label="AÑO"
                        value={client.vehiculo.anio}
                        onChange={onFieldChange}
                        disabled={client.isLoading}
                        type="number"
                        inputProps={{
                            readOnly: actividad.orden > 8
                        }}
                    />
                </div>
                <div style={{ width: 145 }} >
                    <TextField
                        margin="dense"
                        id="motor"
                        name="motor"
                        label="MOTOR"
                        value={client.vehiculo.motor}
                        onChange={onFieldChange}
                        disabled={client.isLoading}
                        type="number"
                        inputProps={{
                            readOnly: actividad.orden > 8
                        }}
                    />
                </div>
                { client.showQuestion && (<FormControlLabel
                    value={client.vehiculo.creaModelo}
                    control={<Switch 
                        checked={client.vehiculo.creaModelo}
                        color="secondary"
                        name="creaModelo"
                        onChange={onFieldChange}
                    />}
                    label="¿Quiere crear un nuevo modelo?"
                    labelPlacement="start"
                />)}
                </div>
                )}
            </DialogContent>
            <DialogActions>
                <Typography color="secondary" >{client.error}</Typography>
                {(client.isLoading && <CircularProgress color="secondary" style={{ height: 32, width: 32 }} />)}
                {!iniciado && (<Button size="small" variant="contained" disabled={client.isLoading} onClick={onHandleSearchModel}>
                    Buscar Modelo
                </Button>)}
                {iniciado && actividad.orden === 8 && (<Button size="small" variant="contained" disabled={client.isLoading} onClick={onHandleSave} >
                    Guardar
                </Button>)}
                <Button color="secondary" size="small" variant="contained" disabled={client.isLoading}  onClick={onHandleCancel}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = state => ({
    actividad: state.process.activities[state.process.index],
    client: state.client,
    iniciado: state.workorder.iniciado,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ClientDialog);