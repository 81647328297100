import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import * as Additive from '../reducers/additive';
import * as Client from '../reducers/client';
import * as Filter from '../reducers/filter';
import * as Dialog from '../reducers/dialog';
import * as Diesel from '../reducers/diesel';
import * as Oil from '../reducers/oil';
import * as Order from '../reducers/order';
import * as Preventive from '../reducers/preventive';
import * as Process from '../reducers/process';
import * as Rating from '../reducers/rating';
import * as Session from '../reducers/session';
import * as Station from '../reducers/station';
import * as Timer from '../reducers/timer';
import * as User from '../reducers/user';
import * as WorkOrder from '../reducers/workorder';
import * as Oferta from '../reducers/oferta';


export default function configureStore (history, initialState) {
  const reducers = {
    additive: Additive.reducer,
    client: Client.reducer,
    dialog: Dialog.reducer,
    diesel: Diesel.reducer,
    oil: Oil.reducer,
    order: Order.reducer,
    filter: Filter.reducer,
    preventive: Preventive.reducer,
    process: Process.reducer,
    rating: Rating.reducer,
    session: Session.reducer,
    station: Station.reducer,
    timer: Timer.reducer,
    user: User.reducer,
    workorder: WorkOrder.reducer,
    oferta: Oferta.reducer
  };

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
}
