import { ProductApiUrl } from '../config';
import axios from 'axios';
import {
    ADD_DETAIL_PRODUCT,
    RECEIVE_PREVENTIVE_LIST,
    REQUEST_PREVENTIVE_LIST,
    UPDATE_STATE_ORDER
} from '../constants/actionTypes';

const initialState = {
    isLoading: false,
    ranking: 0,
    preventives: []
};

export const actionCreators = {
    requestPreventives: () => async (dispatch, getState) => {
        if(getState().preventive.isLoading) {
            return;
        }
        let ranking = getState().preventive.ranking + 1;
        dispatch({ type: REQUEST_PREVENTIVE_LIST });
        var r = getState().workorder.parametro ;
        let url = `${ProductApiUrl}/api/v1/producto/operador?categoriaId=7&codigoSubCategoria=SC015&ranking=${ranking}&tipo=${r}`;
        var response = await axios.get(url);
        var preventives = response.data;

        dispatch({ type: RECEIVE_PREVENTIVE_LIST, preventives });
    },

    addDetailProduct: (detail) => (dispatch, getState) => {
        let details = [...getState().order.details];

        let index = details.findIndex(function(item){
            return item.productoId === detail.productoId;
        });

        let indexA = details.findIndex(function(item){
            return item.productoId === 294;
        });

        if(indexA < 0 && detail.precioVenta === 15.00) {
            return;
        }

        if(indexA >= 0) {
            detail.precioVenta = 15.00;
        }


        if(index >= 0) {
            return;
        }
        else {
            details.push(detail);
        }

        dispatch({ type: ADD_DETAIL_PRODUCT, details });
        dispatch({ type: UPDATE_STATE_ORDER });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case RECEIVE_PREVENTIVE_LIST:
            return {
                ...state,
                isLoading: false,
                preventives: action.preventives
            };
        case REQUEST_PREVENTIVE_LIST:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
        
    }

};