import { createTheme } from '@material-ui/core/styles';

const themes = {
    'default': createTheme({
        palette: {
          primary: {
            main: '#333',
          },
        },
        overrides: {
          MuiInputBase: {
              root: {
                  fontSize: '1.2rem'
              },
              input: {
                  textTransform: 'uppercase'
              }
          },
          MuiFab: {
              root: {
                  fontSize: 12,
                  fontWeight: 600,
                  height: 64,
                  width: 64,
                  marginLeft: 8
              }
          },
          MuiStepLabel: {
              alternativeLabel: {
                  fontSize: 10,
                  fontWeight: 600,
                  '@media (min-width: 1000px)': {
                      fontSize: 12
                  },
                  '@media (min-width: 1200px)': {
                      fontSize: 14
                  },
                  '@media (min-width: 1400px)': {
                      fontSize: 16
                  },
                  '@media (min-width: 1600px)': {
                      fontSize: 18
                  }
              },
              label:{
                  '&.MuiStepLabel-active': {
                      fontWeight: 600
                  },
                  '&.MuiStepLabel-completed': {
                      fontWeight: 600
                  },
                  '&.MuiStepLabel-alternativeLabel': {
                      marginTop: 4
                  }
              }
          },
          MuiStepConnector: {
              active: {
                  top: 18,
                  left: "calc(-50% + 18px)",
                  borderColor: "#eeb02e",
                  '@media (min-width: 1000px)': {
                      top: 24,
                      left: "calc(-50% + 24px)"
                  },
                  '@media (min-width: 1200px)': {
                      top: 30,
                      left: "calc(-50% + 30px)"
                  },
                  '@media (min-width: 1400px)': {
                      top: 36,
                      left: "calc(-50% + 36px)"
                  },
                  '@media (min-width: 1600px)': {
                      top: 42,
                      left: "calc(-50% + 42px)"
                  }
              },
              alternativeLabel: {
                  top: 18,
                  left: "calc(-50% + 18px)",
                  borderColor: "#bdbdbd",
                  '@media (min-width: 1000px)': {
                      top: 24,
                      left: "calc(-50% + 24px)"
                  },
                  '@media (min-width: 1200px)': {
                      top: 30,
                      left: "calc(-50% + 30px)"
                  },
                  '@media (min-width: 1400px)': {
                      top: 36,
                      left: "calc(-50% + 36px)"
                  },
                  '@media (min-width: 1600px)': {
                      top: 42,
                      left: "calc(-50% + 42px)"
                  }
              },
              completed: {
                  top: 18,
                  left: "calc(-50% + 18px)",
                  borderColor: "#33af33",
                  '@media (min-width: 1000px)': {
                      top: 24,
                      left: "calc(-50% + 24px)"
                  },
                  '@media (min-width: 1200px)': {
                      top: 30,
                      left: "calc(-50% + 30px)"
                  },
                  '@media (min-width: 1400px)': {
                      top: 36,
                      left: "calc(-50% + 36px)"
                  },
                  '@media (min-width: 1600px)': {
                      top: 42,
                      left: "calc(-50% + 42px)"
                  }
              },
              lineHorizontal: {
                  borderTopWidth: 1,
                  '@media (min-width: 1200px)': {
                      borderTopWidth: 2
                  }
              },
              line: {
                  borderColor: "inherit"
              }
          },
          MuiBottomNavigation: {
              root: {
                  height: 40
              }
          },
          MuiBottomNavigationAction: {
              root:{
                  '&.Mui-selected': {
                      backgroundColor: "#f00",
                      color: "#fff",
                      fontWeight: 600
                  }
              }
          },
          MuiIcon: {
            root: {
                fontSize: '1.25rem',
                width: 'auto'
            }
          },
          MuiTableCell: {
              sizeSmall: {
                  fontSize: 12,
                  padding: 2
              }
          },
          MuiTypography: {
              h5: {
                  fontSize: 14,
                  fontWeight: 600
              }
          },
          MuiTab: {
              root: {
                  minWidth: 0,
                  fontSize: '0.8125rem',
                  '@media (min-width: 0px)': {
                      minWidth: 0
                  }
              }
          },
          MuiToolbar: {
              regular: {
                  '@media (min-width: 600px)': {
                      minHeight: 48
                  }
              }
          }
        },
        
        typography: { useNextVariants: true, fontSize: 14 }
      })
};

export const createMyTheme = (color) => {
    switch (color) {
        default:
            return themes.default
    }
}