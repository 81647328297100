import { ProductApiUrl } from "../config";
import axios from "axios";

import {
  ADD_DETAIL_PRODUCT,
  CHANGE_NAV_OFERTA,
  ERROR_MESSAGE_DIALOG,
  OPEN_DIALOG_PRODUCT_OFERTA,
  CLOSE_DIALOG_PRODUCT_OFERTA,
  RECEIVE_OFERTA_LIST,
  REQUEST_OFERTA_LIST,
  UPDATE_STATE_ORDER,
} from "../constants/actionTypes";

const initialState = {
  isLoading: false,
  details: [],
  open: false,
  isSent: true,
};

export const actionCreators = {
 
  onCloseProductDialogOferta: () => ({ type: CLOSE_DIALOG_PRODUCT_OFERTA }),
  
  requestOferta: () => async (dispatch, getState) => {
  
    if (getState().oferta.isLoading) {
      // return;
    }
    dispatch({ type: REQUEST_OFERTA_LIST });


    var r = getState().workorder.parametro;
    if (r === "") {
      // dispatch({ type: TOGGLE_SHOWX});
    }
    
    let url2 = `${ProductApiUrl}/api/v1/producto/operador/oferta`;
    var response = await axios.get(url2);
    var details = response.data;

    dispatch({ type: RECEIVE_OFERTA_LIST, details });
  },

  // addQuarterProduct: () => async (dispatch, getState) => {
  //   let details = [...getState().order.details];
  //   let detail = details.filter(function (item) {
  //     return item.codigoCategoria === "C001";
  //   });
  //   console.log("OJODetail" + JSON.stringify(detail));
  //   if (detail.length === 0) {
  //     dispatch({
  //       type: ERROR_MESSAGE_DIALOG,
  //       message: "Agregue un producto del tipo lubricante.",
  //     });
  //     return;
  //   }

  //   let productoId = detail[0].productoId;
  //   let url = `${ProductApiUrl}/api/v1/producto/productocuarto?productoid=${productoId}`;

  //   var response = await axios.get(url);
  //   var cuarto = response.data;

  //   if (cuarto.stock <= 0) {
  //     dispatch({
  //       type: ERROR_MESSAGE_DIALOG,
  //       message: "El producto no cuenta con stock disponible.",
  //     });
  //     return;
  //   }

  //   detail = {
  //     productoId: cuarto.productoId,
  //     dProducto: cuarto.dProducto,
  //     codigoCategoria: "",
  //     cantidad: 1,
  //     precioVenta: cuarto.precio,
  //     puntos: cuarto.puntos,
  //     stock: cuarto.stock - 1,
  //   };

  //   dispatch(actionCreators.addDetailProduct(detail));
  // },

  addDetailProduct: (detail) => (dispatch, getState) => {
    let details = [...getState().order.details];

    let index = details.findIndex(function (item) {
      return item.codigoCategoria === detail.codigoCategoria;
    });

     index = details.findIndex(function (item) {
      return item.productoId === detail.productoId;
    });

    if (index >= 0) {
      if (details[index].stock - detail.cantidad < 0) {
        dispatch({
          type: ERROR_MESSAGE_DIALOG,
          message: "El código ingresado no cuenta con stock disponible.",
        });
        return;
      }

      details[index].cantidad += detail.cantidad;
      details[index].stock -= detail.cantidad;
    } else {
      details.push(detail);
    }

    dispatch({ type: ADD_DETAIL_PRODUCT, details });
    dispatch({ type: UPDATE_STATE_ORDER });
  }
};

export const reducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case CHANGE_NAV_OFERTA:
      return {
        ...state,
        codSubCategoria: action.value,
        ranking: 0,
      };
    case ADD_DETAIL_PRODUCT:
      return Object.assign({}, state, {
        details: action.details,
      });
    case CLOSE_DIALOG_PRODUCT_OFERTA:
      return {
        ...state,
        open: false,
      };
    case OPEN_DIALOG_PRODUCT_OFERTA:
      return {
        ...state,
        open: true,
      };
    case UPDATE_STATE_ORDER:
      return {
        ...state,
        isSent: action.estado | false,
      };
    case RECEIVE_OFERTA_LIST:
      return {
        ...state,
        isLoading: false,
        details: action.details,
      };
    case REQUEST_OFERTA_LIST:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
