import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/preventive';

const Preventive = props => {
    const preventive = props.preventive;

    useEffect(() => {
        props.requestPreventives();
    // eslint-disable-next-line
    }, []);

    function handleClick(item, precio) {
        let detail = {
            productoId: item.productoId,
            dProducto: item.dProducto,
            cantidad: 1,
            precioVenta: precio,
            puntos: item.puntos,
            stock: 1,
            servicio: true
        };

        props.addDetailProduct(detail);
    }

    return (
        <div>
            {preventive.isLoading && (<div style={{ textAlign: "center", minHeight: 485 }}>
                <CircularProgress color="secondary" />
            </div>)}
            {!preventive.isLoading && (<Grid container justify="center" spacing={2} style={{ minHeight: 500 }}>
                {preventive.preventives.map((item, key) => (
                    <Grid key={key} item>
                        <Grid direction="row" alignItems='center' container justify="center" spacing={2}>
                            <Grid item>
                                <Paper style={{ textAlign:"center", boxShadow: "none" }}>
                                    <img style={{ height: 315, width: 315 }} src={item.imagenUri} alt={item.productoId}/>
                                </Paper>
                            </Grid>
                            <Grid style={{ textAlign: "-webkit-center" }} item>
                                <Paper style={{ width: 315, textAlign:"center", boxShadow: "none" }}>
                                    <Typography style={{fontSize: 18, fontWeight: 600, marginBottom: 16}}>{item.dProducto}</Typography>
                                    <Typography style={{fontSize: 14, marginBottom: 24 }}>{item.detalle}</Typography>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
                                        <Paper className="price-paper" onClick={() => handleClick(item, item.precioVenta)} style={{ backgroundColor: "#f5f538", cursor: 'pointer' }} >
                                                <Typography style={{fontSize: 22, fontWeight: 600, lineHeight: 1 }}>{item.precioVenta.toFixed(2)}</Typography>
                                        </Paper>
                                        <Paper className="price-paper" onClick={() => handleClick(item, 15.00)} style={{ backgroundColor: "#f00", color: '#fff', cursor: 'pointer' }} >
                                                <Typography style={{fontSize: 22, fontWeight: 600, lineHeight: 1 }}>{'15.00'}</Typography>
                                        </Paper>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div style={{ color: '#f00', fontWeight: 600 }}>Precio Especial</div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>)}
        </div>
    );
}

const mapStateToProps = state => ({
    preventive: state.preventive
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Preventive);