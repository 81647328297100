import { OperationsApiUrl } from '../config';
import axios from 'axios';
import {
    CONFIRM_MESSAGE_DIALOG,
    CLOSE_MESSAGE_DIALOG,
    ERROR_MESSAGE_DIALOG,
    LOAD_PIQUE_INIT,
    OPEN_MESSAGE_DIALOG,
    OPEN_DIALOG_RATING,
    OPEN_DIALOG_STATION,
    QUESTION_MESSAGE_DIALOG,
    SET_ACTIVITY_INDEX,
    UPDATE_FIELD_CLIENT,
    LOAD_FINISHED,
    LOAD_WORK_ORDER,
    TOGGLE_SHOW_ORDER,
    TOGGLE_OFERTA,
    TOGGLE_SHOW,
    TOGGLE_SHOWX,
    UPDATE_FIELD_STICKER,
    UPDATE_STATE_ORDER,
    START_TIMER,
    STOP_TIMER,
    INIT_WORK_ORDER,
    RESTART_STATE,
    RESTART_STATE_ORDER,
    RESTART_STATE_CLIENT,
    RECEIVE_CLIENT,
    ADD_DETAIL_PRODUCT,
    SET_ACTION_DIALOG,
    CLOSE_DIALOG_STICKER,
    OPEN_DIALOG_STICKER
} from '../constants/actionTypes';

const initialState = {
    action: '',
    iniciado: false,
    actividad: {},
    estacionId: 0,
    piqueId: 0,
    ordenTrabajoId: 0,
    permiso: false,
    solicitaSticker: false,
    estado: '',
    cliente: {
        clienteId: 0,
        nic: '',
        razonSocial: '',
        dTipoCliente: ''
    },
    vehiculo: {
        vehiculoId: 0,
        modeloId: 0,
        placa: '',
        marca: '',
        modelo: '',
        anio: '',
        motor: '',
        kilometraje: ''
    },
    showOrder: false,
    openSticker: false,
    parametro: "",
    show: false,
    showx: false,
};

export const actionCreators = {
    setAction: (action) => ({ type: SET_ACTION_DIALOG, action }),
    createWorkOrder: () => async (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId > 0) {
            return;
        }
        if(getState().client.cliente.clienteId === 0 
            || getState().client.vehiculo.vehiculoId === 0 || getState().client.vehiculo.kilometraje === '' ){
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Faltan datos necesarios para crear la orden de trabajo.' });
            return;
        }

        let data = {
            vehiculoId: getState().client.vehiculo.vehiculoId,
            clienteId: getState().client.cliente.clienteId,
            piqueId: getState().workorder.piqueId,
            tipoDocumentoId: getState().client.cliente.tipoDocumentoId,
            kilometraje: getState().client.vehiculo.kilometraje,
            terminado: false,
            solicitaSticker: getState().workorder.solicitaSticker,
            tiempo: getState().timer.seconds
        };

        try {
            let url = `${OperationsApiUrl}/api/v1/ordentrabajo`;
            let response = await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});
            let ordenTrabajoId = response.data;

            dispatch({ type: LOAD_WORK_ORDER, ordenTrabajoId, solicitaSticker: data.solicitaSticker, estado: 'P' });
        } catch (error) {
            if(error.response) {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
            }
            else{
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Ha ocurrido un error conectando al servidor.' });
            }
        } 
    },
    getWorkOrder: () => async (dispatch, getState) => {
        try {
            let url = `${OperationsApiUrl}/api/v1/ordentrabajo/pique/${getState().workorder.piqueId}`;
            let response = await axios.get(url);
            let data = response.data;

            if(data == null || data === '') {
                if(!getState().user.totalLoad < 4){
                    dispatch({ type: LOAD_FINISHED });
                }
                return;
            }

            let ordenTrabajoId = data.ordenTrabajoId;
            let solicitaSticker = data.solicitaSticker;
            
            let cliente = {
                clienteId: data.clienteId,
                tipoDocumentoId: data.tipoDocumentoId,
                tipoClienteId: data.tipoClienteId,
                ruc: data.ruc,
                dni: data.dni,
                dCliente: data.dCliente,
                dTipoCliente: data.dTipoCliente,
                razonSocial: data.razonSocial,
                primerNombre: data.primerNombre,
                apellidoPaterno: data.apellidoPaterno,
                domicilioFiscal: data.domicilioFiscal,
                distrito: data.distrito,

                vehiculoId: data.vehiculoId,
                marcaId: data.marcaId,
                modeloId: data.modeloId,
                placa: data.placa,
                marca: data.dMarca,
                modelo: data.dModelo,
                anio: data.anio,
                motor: data.motor,
                kilometraje: data.kilometraje,
                puntos: data.puntos
            };

            let actividad;
            if(data.actividadId > 0){
                actividad = {
                    actividadId: data.actividadId,
                    dActividad: data.dActividad,
                    nombreImagen: data.nombreImagen,
                    orden: data.orden,
                    esCondicional: data.esCondicional,
                    esFacturacion: data.esFacturacion,
                    enciendeLuz: data.enciendeLuz,
                    enviaAlmacen: data.enviaAlmacen,
                    muestraPedido: data.muestraPedido,
                    requiereAsistencia: data.requiereAsistencia,
                    solicitaSticker: data.solicitaSticker,
                    cierre: data.cierre
                }
            }
            else{
                actividad = {
                    actividadId: 1,
                    dActividad: 'INICIAR PROCESO',
                    nombreImagen: 'activity0',
                    orden: 1
                }
            }
            
            let detalle = data.detalleProducto;
            let productos = [];

            detalle.forEach(function(p){
                productos.push({
                    productoId: p.productoId,
                    dProducto: p.dProducto,
                    codigoCategoria: p.codigoCategoria,
                    cantidad: p.cantidad,
                    precioVenta: p.precioVenta,
                    puntos: p.puntos,
                    stock: p.stock - p.cantidad,
                    servicio: p.servicio
                });
            });

            dispatch({ type: LOAD_WORK_ORDER, ordenTrabajoId, solicitaSticker, estado: data.estado });
            dispatch({ type: RECEIVE_CLIENT, data: cliente });
            dispatch({ type: ADD_DETAIL_PRODUCT, details: productos });
            dispatch({ type: SET_ACTIVITY_INDEX, index: actividad.orden - 1 });
            dispatch({ type: START_TIMER, seconds: data.tiempoServicio });

            if(productos.length === 0) {
                dispatch({ type: UPDATE_STATE_ORDER, estado: false });
            }

            if(data.estado === 'V' || data.estado === 'F'){
                dispatch({ type: STOP_TIMER });
            }
            if(data.estado === 'F'){
                dispatch(actionCreators.onOpenRatingDialog(ordenTrabajoId));
            }

            if(!getState().user.totalLoad < 4){
                dispatch({ type: LOAD_FINISHED });
            }
        } catch (error) {
            console.log(`Error OT: ${error}`);
        }
    },

    initWorkOrder: () => (dispatch, getState) => {
        dispatch({ type: RESTART_STATE });
        dispatch({ type: INIT_WORK_ORDER });
        dispatch({ type: START_TIMER });
        dispatch({ type: SET_ACTIVITY_INDEX, index: 1 });
    },

    finishWorkOrder: () => async (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId === 0){
            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: STOP_TIMER });
            dispatch({ type: RESTART_STATE });
            dispatch({ type: RESTART_STATE_ORDER });
            dispatch({ type: RESTART_STATE_CLIENT });
            dispatch({ type: SET_ACTIVITY_INDEX, index: 0 });
            return;
        }
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Anulando operación' })

        let url = `${OperationsApiUrl}/api/v1/ordentrabajo/`;
        let data = {
            ordenTrabajoId: getState().workorder.ordenTrabajoId
        };

        try {
            await axios.delete(url, { data: data });

            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: STOP_TIMER });
            dispatch({ type: RESTART_STATE });
            dispatch({ type: RESTART_STATE_ORDER });
            dispatch({ type: RESTART_STATE_CLIENT });
            dispatch({ type: SET_ACTIVITY_INDEX, index: 0 });
            
        } catch (error) {
            console.log(error);
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 
    },
    onOpenRatingDialog: (ordenTrabajoId) => (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId === ordenTrabajoId) {
            dispatch({ type: OPEN_DIALOG_RATING });
        }
    },
    onOpenDialog: () => ({ type: OPEN_DIALOG_STATION }),
    onLoadPique: (piqueId) => ({ type: LOAD_PIQUE_INIT, piqueId }),
    onShowLoading: (message) => ({ type: OPEN_MESSAGE_DIALOG, message }),
    onUpdateField: (key, value) => ({ type: UPDATE_FIELD_CLIENT, key, value }),
    onUpdateFieldSticker: (key, value) => ({ type: UPDATE_FIELD_STICKER, key, value }),
    onUpdateProductoOT: () => async (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId === 0){
            return;
        }
        if(getState().order.details.length === 0) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'No ha seleccionado producto para enviar a almacén' });
            return;
        }
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Enviando pedido a almacén' });
        let productos = [];
        getState().order.details.forEach(function(d) {
            if(d.productoId > 0) {
                productos.push({
                    productoId: d.productoId,
                    cantidad: d.cantidad
                });
            }
        });

        let url = `${OperationsApiUrl}/api/v1/ordentrabajo/dordentrabajoproducto`;

        let data = {
            ordenTrabajoId: getState().workorder.ordenTrabajoId,
            productos: productos
        };

        try {
            await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});
            dispatch({ type: UPDATE_STATE_ORDER, estado: true });
            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: CONFIRM_MESSAGE_DIALOG, message: 'Pedido solicitado correctamente.' });
            
        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 
    },
    onSendToSales: () => async (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId === 0){
            return;
        }
        if(!getState().order.isSent) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Falta confirmar envío a almacén' });
            return;
        }
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Enviando orden de trabajo a caja' });

        let url = `${OperationsApiUrl}/api/v1/ordentrabajo/venta`;
        let data = {
            ordenTrabajoId: getState().workorder.ordenTrabajoId
        };

        try {
            await axios.put(url, data, {headers: {'Content-Type': 'application/json'}});

            let solicitaSticker = getState().workorder.solicitaSticker;
            dispatch({ type: LOAD_WORK_ORDER, ordenTrabajoId: data.ordenTrabajoId, solicitaSticker, estado: 'V' });
            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: STOP_TIMER });
            dispatch({ type: CONFIRM_MESSAGE_DIALOG, message: 'Orden de trabajo enviado correctamente.' });
        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 
    },

    onUpdateModelFilter: () => async (dispatch, getState) => {
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Asignando modelo-marca' });

        let url = `${OperationsApiUrl}/api/v1/modelofiltro`;
        let data = {
            modeloId: getState().client.vehiculo.modeloId,
            productoId: getState().filter.default
        };

        try {
            await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});

            dispatch({ type: CLOSE_MESSAGE_DIALOG });

        } catch (error) {
            if(error.response.data.message) {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
            }
            else {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Ocurrio un error al conectar al servidor.' });
            }
            
        }


        dispatch( { type: SET_ACTION_DIALOG, action: 'filter' });
    },

    onUnassignStation: () => async (dispatch, getState) => {
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Liberando pique' });

        let url = `${OperationsApiUrl}/api/v1/piques/liberar`;
        let data = {
            piqueId: getState().workorder.piqueId
        };

        try {
            await axios.put(url, data, {headers: {'Content-Type': 'application/json'}});

            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: START_TIMER, seconds: 0 });
            dispatch({ type: STOP_TIMER });
            dispatch({ type: LOAD_PIQUE_INIT, piqueId: 0 });
            dispatch({ type: RESTART_STATE });
            dispatch({ type: RESTART_STATE_ORDER });
            dispatch({ type: RESTART_STATE_CLIENT });
            dispatch({ type: SET_ACTIVITY_INDEX, index: 0 });
        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 

        
    },
    onRequestSticker: () => async (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId === 0) {
            return;
        }
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Actualizando solicitud de sticker de seguridad.' });

        let url = `${OperationsApiUrl}/api/v1/ordentrabajo/solicitarsticker`;
        let data = {
            ordenTrabajoId: getState().workorder.ordenTrabajoId,
            solicitaSticker: getState().workorder.solicitaSticker
        };

        try {
            await axios.put(url, data, {headers: {'Content-Type': 'application/json'}});

            dispatch({ type: CLOSE_MESSAGE_DIALOG });
        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 

    },
    onShowQuestionDialog: (message) => ({ type: QUESTION_MESSAGE_DIALOG, message }),
    onCloseStickerDialog: () => ({ type: CLOSE_DIALOG_STICKER })
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case SET_ACTION_DIALOG:
            return {
                ...state,
                action: action.action
            };
        case UPDATE_FIELD_CLIENT:
            return { 
                ...state,
                vehiculo: {
                    ...state.vehiculo,
                    [action.key]: action.value
                }
            };
        case UPDATE_FIELD_STICKER:
            return {
                ...state,
                [action.key]: action.value === 'true' ? false : true
            }
        case LOAD_PIQUE_INIT:
            return {
                ...state,
                estacionId: action.estacionId,
                piqueId: action.piqueId
            };
        case LOAD_WORK_ORDER:
            return {
                ...state,
                ordenTrabajoId: action.ordenTrabajoId,
                solicitaSticker: action.solicitaSticker,
                estado: action.estado,
                iniciado: true
            }
        case TOGGLE_SHOW_ORDER:
            return {
                ...state,
                showOrder: !state.showOrder
            }
        case TOGGLE_OFERTA:
                return {
                    ...state,
                    parametro: action.r
                }
        case TOGGLE_SHOW:
                    return {
                        ...state,
                        show: !state.show
                }       
        case TOGGLE_SHOWX:
                return {
                    ...state,
                    showx: action.r
                } 
        case INIT_WORK_ORDER:
            return {
                ...state,
                iniciado: true
            }
        case RESTART_STATE:
            return {
                ...initialState,
                piqueId: state.piqueId
            }
        case CLOSE_DIALOG_STICKER:
            return {
                ...state,
                openSticker: false
            }
        case OPEN_DIALOG_STICKER:
            return {
                ...state,
                openSticker: true
            }
        default:
            return state;
        
    }


};