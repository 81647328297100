import { SalesApiUrl } from '../config';
import React, {useEffect} from 'react';
import clsx from 'clsx';
import { HubConnectionBuilder } from '@microsoft/signalr';

import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';

import Activity from './Activity';
import AppMenu from './AppMenu';
import ClientDialog from './ClientDialog';
import ClientWidget from './ClientWidget';
import Products from './Products';
import MessageDialog from './MessageDialog';
import Order from './Order';
import Process from './Process';
import ProductsDialog from './ProductsDialog';
import ProductsDialogOferta from './ProductsDialogOferta';
import RatingDialog from './RatingDialog';
import StationDialog from './StationDialog';
import Timer from './Timer';
import Total from './Total';
import Sticker from './Sticker';
import StickerDialog from './StickerDialog';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/workorder';



const useStyles = makeStyles(() => ({
    button: {
        fontSize: 7,
        height: 33,
        width: 33,
        '@media (min-width: 800px)': {
            fontSize: 9,
            height: 42,
            width: 42
        },
        '@media (min-width: 1000px)': {
            fontSize: 10,
            height: 51,
            width: 51
        },
        '@media (min-width: 1200px)': {
            fontSize: 12,
            height: 60,
            width: 60
        },
        '@media (min-width: 1400px)': {
            fontSize: 13.5,
            height: 69,
            width: 69
        },
        '@media (min-width: 1600px)': {
            fontSize: 15,
            height: 78,
            width: 78
        }
    },
    container: {
        display: "flex; display: -ms-flexbox",
        flexGrow: 1,
        position: "relative",
        minWidth: "100%",
        width: "max-content"
    },
    icon: {
        fontSize: 12,
        '@media (min-width: 800px)': {
            fontSize: 16
        },
        '@media (min-width: 1000px)': {
            fontSize: 20
        },
        '@media (min-width: 1200px)': {
            fontSize: 24
        },
        '@media (min-width: 1400px)': {
            fontSize: 28
        },
        '@media (min-width: 1600px)': {
            fontSize: 32
        }
    },
    layout: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        minWidth: "100%",
        width: "max-content"
    },
    regionCenter: {
        display: "flex",
        flexGrow: 1
    },
    regionLeft: {
        minWidth: 165,
        width: 165,
        '@media (min-width: 800px)': {
            minWidth: 210,
            width: 210
        },
        '@media (min-width: 1000px)': {
            minWidth: 255,
            width: 255
        },
        '@media (min-width: 1200px)': {
            minWidth: 300,
            width: 300
        },
        '@media (min-width: 1400px)': {
            minWidth: 345,
            width: 345
        },
        '@media (min-width: 1600px)': {
            minWidth: 390,
            width: 390
        }
    },
    regionRight: {
        minWidth: 165,
        width: 165,
        '@media (min-width: 800px)': {
            minWidth: 210,
            width: 210
        },
        '@media (min-width: 1000px)': {
            minWidth: 255,
            width: 255
        },
        '@media (min-width: 1200px)': {
            minWidth: 300,
            width: 300
        },
        '@media (min-width: 1400px)': {
            minWidth: 345,
            width: 345
        },
        '@media (min-width: 1600px)': {
            minWidth: 390,
            width: 390
        }
    }
}));

const WorkOrder = props => {
    const classes = useStyles();
    const workorder = props.workorder;

    useEffect(() => {
        if(props.totalLoad >= 4) {
          connect();  
        }
      // eslint-disable-next-line
      }, [props.totalLoad]);

    async function connect() {
        let connection = new HubConnectionBuilder()
          .withUrl(`${SalesApiUrl}/ventahub`)
          .build();

        connection.on("onOrdenTrabajoFacturado", ordenTrabajoId => {
            props.onOpenRatingDialog(ordenTrabajoId);
        });
        
        connection.start().then(r => console.log('Conectado')).catch(err => console.error(err.toString()));
    
        connection.onclose(async () => {
          console.log('conexión interrumpida');
          await start(connection);
        });
    }

    async function start(connection) {
        try {
            await connection.start();
            console.log("reconectado");
        } catch (err) {
            console.log(err);
            setTimeout(() => start(connection), 5000);
        }
    }

    function handleClickClose() {
        props.onUnassignStation();
    }

    function handleClickInit() {
        props.initWorkOrder();
    }

    function handleClickOpen() {
        props.onOpenDialog();
    }

    function handleClickFinish() {
        props.setAction('finish');
        props.onShowQuestionDialog('Se anulara la orden de trabajo, ¿Desea continuar?');
    }

    function handleClickOrder() {
        if(props.workorder.ordenTrabajoId > 0){
            props.setAction('order');
            props.onShowQuestionDialog('¿Solicitar productos a almacén?');
        }
    }

    function handleClickSales() {
        if(props.workorder.ordenTrabajoId > 0){
            props.setAction('sales');
            props.onShowQuestionDialog('¿Enviar a facturación la orden de trabajo?');
        }
    }

    function onHandleClickYes() {

        switch(props.workorder.action) {
            case 'finish':
                props.finishWorkOrder();
                break;
            case 'order':
                props.onUpdateProductoOT();
                break;
            case 'sales':
                props.onSendToSales();
                break;
            case 'filter':
                props.onUpdateModelFilter();
                break;
            default:
                return;

        }
    }

    return(
        <div className={classes.layout}>
            <AppMenu />
            <div className={classes.container}>
                <div className={classes.regionLeft}>
                    <Activity />
                    <ClientWidget />
                    <Sticker />
                </div>
                <div className={classes.regionCenter}>
                    {!workorder.showOrder && (<Process />)}
                    {workorder.showOrder && (<Order />)}
                </div>
                <div className={classes.regionRight}>
                    <Timer />
                    <div style={{ padding: 2 }}>
                        <Fab className={classes.button} color="secondary" aria-label="iniciar" onClick={handleClickInit} disabled={workorder.piqueId === 0 || workorder.iniciado} >
                            <Icon className={clsx("fas fa-play", classes.icon)} />
                        </Fab>
                        <Fab className={classes.button} color="secondary" aria-label="suspender" onClick= {handleClickFinish.bind(this)} disabled={!workorder.iniciado || workorder.estado === 'V'} >
                            <Icon className={clsx("fas fa-stop", classes.icon)} />
                        </Fab>
                    </div>
                    <Products />
                    <Total />
                    <div style={{ padding: 2 }}>
                        <Fab className={classes.button} color="secondary" aria-label="enviar pedido" style={{ backgroundColor: ( props.isSent || workorder.ordenTrabajoId === 0 ? '' : '#1e921e') }} onClick={handleClickOrder} 
                             disabled={workorder.ordenTrabajoId === 0}>
                            <Icon className={clsx("fas fa-shopping-cart", classes.icon)} />
                        </Fab>
                        <Fab className={classes.button} color="secondary" aria-label="enviar venta" onClick={handleClickSales} disabled={workorder.ordenTrabajoId === 0 || workorder.estado === 'V'} >
                            <Icon className={clsx("fas fa-dollar-sign", classes.icon)} />
                        </Fab>
                    </div>
                </div>
                <div className='button-container'>
                    {workorder.piqueId === 0 && (<Fab className={classes.button} color="secondary" aria-label="ingreso" onClick={handleClickOpen}>
                        Ingreso
                    </Fab>)}
                    {workorder.piqueId > 0 && !workorder.permiso && (<Fab className={classes.button} color="secondary" aria-label="permiso">
                        Permiso
                    </Fab>)}
                    {workorder.piqueId > 0 && workorder.permiso && (<Fab className={classes.button} color="secondary" aria-label="retorno">
                        Retorno
                    </Fab>)}
                    {workorder.piqueId > 0 && (<Fab className={classes.button} color="secondary" aria-label="salida" onClick={handleClickClose}>
                        Salida
                    </Fab>)}
                </div>
                
            </div>
            {props.dialog.open && (<MessageDialog action={onHandleClickYes} />)}
            <StationDialog />
            <ClientDialog />
            <ProductsDialog />
            <ProductsDialogOferta /> 
            <RatingDialog />
            <StickerDialog />
        </div>
    )
}

const mapStateToProps = state => ({
    workorder: state.workorder,
    dialog: state.dialog,
    isSent: state.order.isSent,
    totalLoad: state.user.totalLoad
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(WorkOrder);