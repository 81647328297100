import React from 'react';
import { Dialog, 
    DialogTitle, 
    DialogActions, 
    Button, 
    DialogContent, 
    List, 
    ListItem, 
    ListItemText, 
    CircularProgress, 
    ListItemSecondaryAction,
    Typography,
    Avatar} from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/station';

const StationDialog = props => {
    const station = props.station;

    function onDialogEnter() {
        props.onRequestStations();
    }

    function onHandleCancel() {
        props.onCloseDialog();
    }

    function onListItem(station) {
        props.onCloseDialog();
        props.onAssignStation(station.piqueId);
    }

    function fnConvertSecondsToTime(seconds) {
        let hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    }

    return(
        <Dialog aria-labelledby="station-dialog-title" open={station.open} onEnter={onDialogEnter} >
            <DialogTitle id="station-dialog-title">SELECCIONE PIQUE</DialogTitle>
            <DialogContent dividers >
                <div style={{width: 450}} ></div>
                { station.isLoading && (<div style={{textAlign: 'center', width: 450}} ><CircularProgress color="secondary" /></div>) }
                { !station.isLoading && (<List>
                    {station.stations.map(pique => (
                    <ListItem key={pique.piqueId} onClick={!pique.tiempo ? onListItem.bind(props,pique) : null} button={!pique.tiempo} >
                        <Avatar style={{ backgroundColor: (pique.tiempo === null ? '#efb001' : '#999'), marginRight: 12 }}>{'P' + pique.numero}</Avatar>
                        <ListItemText primary={pique.dPique} secondary={pique.dUsuario.length === 0 ? '' : (pique.dUsuario + ' / Tiempo activo ' + fnConvertSecondsToTime(pique.tiempo))} />
                        <ListItemSecondaryAction>
                            <Typography color={pique.tiempo == null ? 'textPrimary' : 'textSecondary'}>{pique.tiempo == null ? 'Disponible' : 'Ocupado'}</Typography>
                        </ListItemSecondaryAction>
                    </ListItem>
                    ))}
                </List>)}
            </DialogContent>
            <DialogActions>
            <Button onClick={onHandleCancel} color="secondary">
                Cancelar
            </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = state => ({
    station: state.station
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(StationDialog);