import React from 'react';
import { AppBar, Tabs, Tab } from '@material-ui/core';
import Additive from './Additive';
import Diesel from './Diesel';
import Oil from './Oil';
import Filter from './Filter';
import Preventive from './Preventive';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/order';

const Order = props => {
    const order = props.order;

    return(
        <div className="order-panel" >
            <AppBar position="static" color="default">
                <Tabs
                    value={order.tabIndex}
                    onChange={props.onChangeTab}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"    
                >
                    <Tab label="Lubricantes (Gasolina)" />
                    <Tab label="Lubricantes (Diesel)"  />
                    <Tab label="Filtros" />
                    <Tab label="Aditivos" />
                    <Tab label="Prevención" />
                </Tabs>
            </AppBar>
            {order.tabIndex === 0 && <div style={{ padding: 16 }}><Oil /></div>}
            {order.tabIndex === 1 && <div style={{ padding: 16 }}><Diesel/></div>}
            {order.tabIndex === 2 && <div style={{ padding: 16 }}><Filter /></div>}
            {order.tabIndex === 3 && <div style={{ padding: 16 }}><Additive /></div>}
            {order.tabIndex === 4 && <div style={{ padding: 16 }}><Preventive /></div>}
        </div>
    )
}

const mapStateToProps = state => ({
    order: state.order
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(Order);