import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, InputBase, InputLabel, CardActions, Button } from '@material-ui/core';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/client';

const useStyles = makeStyles(() => ({
    button: {
        fontSize: 9,
        minWidth: 40,
        '@media (min-width: 800px)': {
            fontSize: 10,
            minWidth: 48
        },
        '@media (min-width: 1000px)': {
            fontSize: 11,
            minWidth: 56
        },
        '@media (min-width: 1200px)': {
            fontSize: 12,
            minWidth: 64
        },
        '@media (min-width: 1400px)': {
            fontSize: 14,
            minWidth: 72
        },
        '@media (min-width: 1600px)': {
            fontSize: 16,
            minWidth: 80
        }
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0
        }
    },
    header: {
        paddingTop: 4,
        paddingBottom: 12
    },
    headerText: {
        fontSize: 10,
        '@media (min-width: 800px)': {
            fontSize: 11
        },
        '@media (min-width: 1000px)': {
            fontSize: 12
        },
        '@media (min-width: 1200px)': {
            fontSize: 14
        },
        '@media (min-width: 1400px)': {
            fontSize: 16
        },
        '@media (min-width: 1600px)': {
            fontSize: 18
        }
    },
    label: {
        color: "#000",
        fontSize: 8,
        fontWeight: 600,
        width: 80,
        padding: "6px 6px 0 0",
        textAlign: "right",
        '@media (min-width: 800px)': {
            fontSize: 9,
            width: 72
        },
        '@media (min-width: 1000px)': {
            fontSize: 10,
            width: 72
        },
        '@media (min-width: 1200px)': {
            fontSize: 12,
            width: 92
        },
        '@media (min-width: 1400px)': {
            fontSize: 14,
            width: 106
        },
        '@media (min-width: 1600px)': {
            fontSize: 16,
            width: 120
        }
    },
    panel:  {
        backgroundColor: "#efb001",
        borderRadius: 4,
        margin: "4px 2px 2px 4px",
        padding: 2,
        textAlign: "center",
        '@media (min-width: 800px)': {
            padding: 2
        },
        '@media (min-width: 1000px)': {
            padding: 4
        },
        '@media (min-width: 1200px)': {
            padding: 4
        },
        '@media (min-width: 1400px)': {
            padding: 6
        },
        '@media (min-width: 1600px)': {
            padding: 8
        }
    },
    text: {
        backgroundColor: "#fff",
        borderColor: "#585454",
        borderStyle: "solid",
        borderWidth: 1,
        flexGrow: 1,
        fontSize: 8,
        fontWeight: 600,
        height: 18,
        paddingLeft: 2,
        '@media (min-width: 800px)': {
            fontSize: 10,
            height: 20,
            paddingLeft: 2
        },
        '@media (min-width: 1000px)': {
            fontSize: 12,
            height: 22,
            paddingLeft: 4
        },
        '@media (min-width: 1200px)': {
            fontSize: 14,
            height: 24,
            paddingLeft: 4
        },
        '@media (min-width: 1400px)': {
            fontSize: 16,
            height: 26,
            paddingLeft: 6
        },
        '@media (min-width: 1600px)': {
            fontSize: 18,
            height: 28,
            paddingLeft: 6
        }
    },
    readOnly: {
        backgroundColor: "#f7d882 !important"
    },
    row: {
        display: "flex",
        paddingBottom: 4
    }
}));

const ClientWidget = props => {
    const classes = useStyles();
    const actividad = props.actividad;
    const cliente = props.cliente;
    const vehiculo = props.vehiculo;
    
    function onFieldChange(event) {
        props.onUpdateField(event.target.name, event.target.value);
    }

    function onTxtPlacaKeyPress(event) {
        if (event.key === 'Enter') {
            props.onGetClientByPlaque();
        }
    }

    return (
        <Card className={classes.panel}>
            <CardHeader className={classes.header} classes={{ title: classes.headerText }} title="VEHÍCULO"/>
            <CardContent className={classes.content}>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="placa">PLACA</InputLabel>
                    <InputBase
                        id="placa"
                        name="placa"
                        autoComplete="off"
                        className={`${classes.text} ${props.iniciado && actividad.requiereCliente ? '' : classes.readOnly}`}
                        value={vehiculo.placa}
                        onChange={onFieldChange}
                        onKeyPress={onTxtPlacaKeyPress}
                        readOnly={!props.iniciado || !actividad.requiereCliente}
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="razon-social">CLIENTE</InputLabel>
                    <InputBase 
                        id="razon-social"
                        className={`${classes.text} ${classes.readOnly}`}
                        value={cliente.dCliente}
                        readOnly
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="marca">MARCA</InputLabel>
                    <InputBase 
                        id="marca"
                        className={`${classes.text} ${classes.readOnly}`}
                        value={vehiculo.dMarca}
                        readOnly
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="modelo">MODELO</InputLabel>
                    <InputBase 
                        id="modelo"
                        className={`${classes.text} ${classes.readOnly}`}
                        value={vehiculo.dModelo}
                        readOnly
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="anio">AÑO</InputLabel>
                    <InputBase 
                        id="anio"
                        className={`${classes.text} ${classes.readOnly}`}
                        value={vehiculo.anio}
                        readOnly
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="motor">MOTOR</InputLabel>
                    <InputBase 
                        id="motor"
                        className={`${classes.text} ${classes.readOnly}`}
                        value={vehiculo.motor}
                        readOnly
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="tipo-cliente">TIPO CLIENTE</InputLabel>
                    <InputBase 
                        id="tipo-cliente"
                        className={`${classes.text} ${classes.readOnly}`}
                        value={cliente.dTipoCliente}
                        readOnly
                    />
                </div>
                <div className={classes.row}>
                    <InputLabel className={classes.label} htmlFor="kilometraje">KILOMETRAJE</InputLabel>
                    <InputBase 
                        id="kilometraje"
                        name="kilometraje"
                        autoComplete="off"
                        className={`${classes.text} ${props.iniciado && actividad.requiereCliente ? '' : classes.readOnly}`}
                        value={vehiculo.kilometraje}
                        onChange={onFieldChange}
                        readOnly={!props.iniciado || !actividad.requiereCliente}
                        type="number"
                    />
                </div>
            </CardContent>
            <CardActions>
                <Button className={classes.button} color="secondary" size="small" variant="contained" onClick={props.onOpenClientDialog} >DATOS</Button>
                <Button className={classes.button} color="secondary" size="small" variant="contained" onClick={props.onOpenStickerDialog} >VER STICKER</Button>
            </CardActions>
        </Card>
    );
};

const mapStateToProps = state => ({
    actividad: state.process.activities[state.process.index],
    cliente: state.client.cliente,
    iniciado: state.workorder.iniciado,
    vehiculo: state.client.vehiculo
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ClientWidget);