import { ProductApiUrl } from '../config';
import axios from 'axios';

import {
    ADD_DETAIL_PRODUCT,
    CHANGE_NAV_DIESEL,
    DECREMENT_RANKING_DIESEL,
    ERROR_MESSAGE_DIALOG,
    INCREMENT_RANKING_DIESEL,
    RECEIVE_DIESEL_LIST,
    REQUEST_DIESEL_LIST,
    UPDATE_STATE_ORDER
} from '../constants/actionTypes';

const initialState = {
    codSubCategoria: 'S',
    isLoading: false,
    ranking: 0,
    diesels: []
};

export const actionCreators = {
    decrementRanking: () => ({ type: DECREMENT_RANKING_DIESEL }),
    incrementRanking: () => ({ type: INCREMENT_RANKING_DIESEL }),
    changeNav: (_e, value) => (dispatch) => {
        dispatch({ type: CHANGE_NAV_DIESEL, value });
        dispatch(actionCreators.requestDiesels());
    },
    requestDiesels: () => async (dispatch, getState) => {
        if(getState().diesel.isLoading) {
            return;
        }
        dispatch({ type: REQUEST_DIESEL_LIST });

        let codSubCategoria = getState().diesel.codSubCategoria;
        var r = getState().workorder.parametro ;
        let ranking = getState().diesel.ranking + 1;
        let url = `${ProductApiUrl}/api/v1/producto/operador?categoriaId=1&codigoSubCategoria=${codSubCategoria}&combustible=D&ranking=${ranking}&tipo=${r}`;
        var response = await axios.get(url);
        var diesels = response.data;

        dispatch({ type: RECEIVE_DIESEL_LIST, diesels });
    },

    addQuarterProduct: () => async (dispatch, getState) => {
        let details = [...getState().order.details];
        let detail = details.filter(function(item){
            return item.codigoCategoria === 'C001'
        });

        if(detail.length === 0){
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Agregue un producto del tipo lubricante.' });
            return;
        }

        let productoId = detail[0].productoId;
        let url = `${ProductApiUrl}/api/v1/producto/productocuarto?productoid=${productoId}`;

        var response = await axios.get(url);
        var cuarto = response.data;

        if(cuarto.stock <= 0) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'El producto no cuenta con stock disponible.' });
            return;
        }

        detail = {
            productoId: cuarto.productoId,
            dProducto: cuarto.dProducto,
            codigoCategoria: '',
            cantidad: 1,
            precioVenta: cuarto.precio,
            puntos: cuarto.puntos,
            stock: cuarto.stock - 1
        };

        dispatch(actionCreators.addDetailProduct(detail));

    },

    addDetailProduct: (detail) => (dispatch, getState) => {
        let details = [...getState().order.details];

        let index = details.findIndex(function(item){
            return item.codigoCategoria === detail.codigoCategoria;
        });

        if(index >= 0 && detail.codigoCategoria !== '') {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Solo puede agregar un producto del tipo lubricante.' });
            return;
        }

        index = details.findIndex(function(item){
            return item.productoId === detail.productoId; //&& item.codigoCategoria === detail.codigoCategoria;
        });

        if(index >= 0) {
            if(details[index].stock - detail.cantidad < 0) {
                dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'El código ingresado no cuenta con stock disponible.' });
                return;
            }

            details[index].cantidad += detail.cantidad;
            details[index].stock -= detail.cantidad;
        }
        else {
            details.push(detail);
        }

        dispatch({ type: ADD_DETAIL_PRODUCT, details });
        dispatch({ type: UPDATE_STATE_ORDER });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type) {
        case CHANGE_NAV_DIESEL:
            return {
                ...state,
                codSubCategoria: action.value,
                ranking: 0
            };
        case DECREMENT_RANKING_DIESEL:
            return {
                ...state,
                ranking: state.ranking - 1
            };
        case INCREMENT_RANKING_DIESEL:
            return {
                ...state,
                ranking: state.ranking + 1
            };
        case RECEIVE_DIESEL_LIST:
            return {
                ...state,
                isLoading: false,
                diesels: action.diesels
            };
        case REQUEST_DIESEL_LIST:
            return {
                ...state,
                isLoading: true
            };
        default:
            return state;
    }

};