import React from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/order';

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: 27
    },
    table: {
        minWidth: 450
    },
    tableRow: {
        fontSize: 18,
        fontWeight: 600
    }
}));

const ProductsDialog = props => {
    const classes = useStyles();
    const order = props.order;
 
    function onHandleClick(productoId, cantidad) {
        props.onModifyProductoOT(productoId, cantidad);
    }

    return (
        <Dialog aria-labelledby="product-dialog-title" open={order.open} maxWidth="md" >
            <DialogContent >
                <Table className={classes.table} size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRow}>PRODUCTOS</TableCell>
                            <TableCell className={classes.tableRow}>CANT</TableCell>
                            <TableCell className={classes.tableRow}>TOTAL</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>    
                    <TableBody>
                        {order.details.map((item, key) => (<TableRow key={key}>
                            <TableCell className={classes.tableRow}>{item.dProducto}</TableCell>
                            <TableCell className={classes.tableRow}>{item.cantidad}</TableCell>
                            <TableCell className={classes.tableRow}>{item.precioVenta*item.cantidad}</TableCell>
                            <TableCell>
                                {!item.servicio && (<IconButton color="default" aria-label="add" onClick={onHandleClick.bind(props, item.productoId, 1)} >
                                    <Icon className={clsx("fas fa-plus-circle", classes.Icon)} />
                                </IconButton>)}
                                <IconButton color="secondary" aria-label="remove" onClick={onHandleClick.bind(props, item.productoId, -1)} >
                                    <Icon className={clsx("fas fa-minus-circle", classes.Icon)} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCloseProductDialog} >CERRAR</Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = state => ({
    order: state.order
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(ProductsDialog);