import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    background: {
        background: 'url("/images/background-orange.jpg") no-repeat',
        backgroundSize: 'cover',
        height: "100vh"
    },
    wrap: {
        left: '50%',
        position: 'absolute',
        textAlign: 'center',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    title: {
        color: '#fff',
        fontSize: 60,
        fontWeight: 600,
        margin: 0
    },
    text: {
        color: '#fff',
        fontSize: 24,
        margin: 0
    }

}));

export default () => {
    const classes = useStyles();

    return (<div className={classes.background}>
        <div className={classes.wrap}>
            <p className={classes.title}>404</p>
            <p className={classes.text}>Esta no es la página que estabas buscando.</p>
        </div>
    </div>)
};