//dialog
export const CLOSE_MESSAGE_DIALOG = 'CLOSE_MESSAGE_DIALOG';
export const CONFIRM_MESSAGE_DIALOG = 'CONFIRM_MESSAGE_DIALOG';
export const ERROR_MESSAGE_DIALOG = 'ERROR_MESSAGE_DIALOG';
export const OPEN_MESSAGE_DIALOG = 'OPEN_MESSAGE_DIALOG';
export const TOGGLE_OFERTA = 'TOGGLE_OFERTA';
export const TOGGLE_SHOW = 'TOGGLE_SHOW';
export const TOGGLE_SHOWX = 'TOGGLE_SHOWX';
export const QUESTION_MESSAGE_DIALOG = 'QUESTION_MESSAGE_DIALOG'
//process(Flujo del proceso)
export const RECEIVE_ACTIVITIES_LIST = 'RECEIVE_ACTIVITIES_LIST';
export const SET_ACTIVITY_INDEX = 'SET_ACTIVITY_INDEX';
//session
export const RECEIVE_SESSION = 'RECEIVE_SESSION';
export const REQUEST_SESSION = 'REQUEST_SESSION';
export const SET_ACCOUNTS='SET_ACCOUNTS';
export const SET_DATA_USER = 'SET_DATA_USER';
//timer
export const INCREMENT_TIMER = 'INCREMENT_TIMER';
export const START_TIMER = 'START_TIMER';
export const STOP_TIMER = 'STOP_TIMER';
//user
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const LOAD_FINISHED = 'LOAD_FINISHED';
export const PRELOAD_FINISH = 'PRELOAD_FINISH';
export const INCREMENT_SECONDS = 'INCREMENT_SECONDS';


//commons
export const RESTART_STATE = 'RESTART_STATE';//workorder
export const LOAD_PIQUE_INIT = 'LOAD_PIQUE_INIT';
export const LOAD_WORK_ORDER = 'LOAD_WORK_ORDER';
export const UPDATE_FIELD_CLIENT = 'UPDATE_FIELD_CLIENT';
export const TOGGLE_SHOW_ORDER = 'TOGGLE_SHOW_ORDER';
export const UPDATE_FIELD_STICKER = 'UPDATE_FIELD_STICKER';
export const INIT_WORK_ORDER = 'INIT_WORK_ORDER';
export const FINISH_WORK_ORDER = 'FINISH_WORK_ORDER'
export const SET_ACTION_DIALOG = 'SET_ACTION_DIALOG';
//station(Pique)
export const CLOSE_DIALOG_STATION = 'CLOSE_DIALOG_STATION';
export const OPEN_DIALOG_STATION = 'OPEN_DIALOG_STATION';
export const REQUEST_STATION_LIST = 'REQUEST_STATION_LIST';
export const RECEIVE_STATION_LIST = 'RECEIVE_STATION_LIST';

//order
export const ADD_DETAIL_PRODUCT = 'ADD_DETAIL_PRODUCT';
export const CHANGE_TAB_INDEX = 'CHANGE_TAB_INDEX';
export const CLOSE_DIALOG_PRODUCT = 'CLOSE_DIALOG_PRODUCT';
export const OPEN_DIALOG_PRODUCT = 'OPEN_DIALOG_PRODUCT';
export const UPDATE_STATE_ORDER = 'UPDATE_STATE_ORDER';
export const RESTART_STATE_ORDER = 'RESTART_STATE_ORDER';

//additive
export const DECREMENT_RANKING_ADDITIVE = 'DECREMENT_RANKING_ADDITIVE';
export const INCREMENT_RANKING_ADDITIVE = 'INCREMENT_RANKING_ADDITIVE';
export const RECEIVE_ADDITIVE_LIST = 'RECEIVE_ADDITIVE_LIST';
export const REQUEST_ADDITIVE_LIST = 'REQUEST_ADDITIVE_LIST';
//filter
export const CHANGE_NAV_FILTER = 'CHANGE_NAV_FILTER';
export const RECEIVE_FILTER_LIST = 'RECEIVE_FILTER_LIST';
export const REQUEST_FILTER_LIST = 'REQUEST_FILTER_LIST';
export const UPDATE_FIELD_FILTER = 'UPDATE_FIELD_FILTER';
export const UPDATE_FILTER_DEFAULT = 'UPDATE_FILTER_DEFAULT';
//oil
export const CHANGE_NAV_OIL= 'CHANGE_NAV_OIL';
export const DECREMENT_RANKING_OIL = 'DECREMENT_RANKING_OIL';
export const INCREMENT_RANKING_OIL = 'INCREMENT_RANKING_OIL';
export const RECEIVE_OIL_LIST = 'RECEIVE_OIL_LIST';
export const REQUEST_OIL_LIST = 'REQUEST_OIL_LIST';
//oferta
export const CHANGE_NAV_OFERTA= 'CHANGE_NAV_OFERTA';
export const RECEIVE_OFERTA_LIST = 'RECEIVE_OFERTA_LIST';
export const REQUEST_OFERTA_LIST = 'REQUEST_OFERTA_LIST';
export const OPEN_DIALOG_PRODUCT_OFERTA = 'OPEN_DIALOG_PRODUCT_OFERTA';//agrgue2
export const CLOSE_DIALOG_PRODUCT_OFERTA = 'CLOSE_DIALOG_PRODUCT_OFERTA';
// export const ADD_DETAIL_PRODUCT_OFERTA = 'ADD_DETAIL_PRODUCT_OFERTA';
//diesel
export const CHANGE_NAV_DIESEL= 'CHANGE_NAV_DIESEL';
export const DECREMENT_RANKING_DIESEL = 'DECREMENT_RANKING_DIESEL';
export const INCREMENT_RANKING_DIESEL = 'INCREMENT_RANKING_DIESEL';
export const RECEIVE_DIESEL_LIST = 'RECEIVE_DIESEL_LIST';
export const REQUEST_DIESEL_LIST = 'REQUEST_DIESEL_LIST';
//client(Popup)
export const CLOSE_DIALOG_CLIENT = 'CLOSE_DIALOG_CLIENT';
export const ERROR_DIALOG_CLIENT = 'ERROR_DIALOG_CLIENT';
export const LOADING_DIALOG_CLIENT = 'LOADING_DIALOG_CLIENT';
export const OPEN_DIALOG_CLIENT = 'OPEN_DIALOG_CLIENT';
export const UPDATE_FIELD_CLIENT_POPUP = 'UPDATE_FIELD_CLIENT_POPUP';
export const CHANGE_NAV_CLIENT = 'CHANGE_NAV_CLIENT';
export const RECEIVE_CLIENT = 'RECEIVE_CLIENT';
export const RECEIVE_MARK_LIST = 'RECEIVE_MARK_LIST';
export const RECEIVE_MODELS_LIST = 'RECEIVE_MODELS_LIST';
export const UPDATE_DATA_CLIENT = 'UPDATE_DATA_CLIENT';
export const UPDATE_DATA_VEHICLE = 'UPDATE_DATA_VEHICLE';
export const RESTART_STATE_CLIENT = 'RESTART_STATE_CLIENT';
export const RECEIVE_CLIENT_SUNAT = 'RECEIVE_CLIENT_SUNAT';
//preventive
export const RECEIVE_PREVENTIVE_LIST = 'RECEIVE_PREVENTIVE_LIST';
export const REQUEST_PREVENTIVE_LIST = 'REQUEST_PREVENTIVE_LIST';
//rating
export const CLOSE_DIALOG_RATING = 'CLOSE_DIALOG_RATING';
export const OPEN_DIALOG_RATING = 'OPEN_DIALOG_RATING';
//sticker
export const CLOSE_DIALOG_STICKER = 'CLOSE_DIALOG_STICKER';
export const OPEN_DIALOG_STICKER = 'OPEN_DIALOG_STICKER';
