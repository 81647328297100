import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../reducers/workorder';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, CircularProgress } from '@material-ui/core';
import { ProductApiUrl, OperationsApiUrl } from '../config';

const useStyles = makeStyles(() => ({
    table: {
        minWidth: 450
    },
    tableRow: {
        fontSize: 18,
        fontWeight: 600
    }
}));

const StickerDialog = props => {
    const classes = useStyles();
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [products, setProducts] = React.useState([]);
    const [placa, setPlaca] = React.useState('');

    function onFieldChange(event) {
        setPlaca(event.target.value);
    }

    function onClose() {
        setPlaca('');
        setLoading(false);
        setProducts([]);
        setError('');
        props.onCloseStickerDialog();
    }

    async function onTxtPlacaKeyPress(event) {
        if (event.key === 'Enter') {
            setLoading(true);
            setProducts([]);
            setError('');

            try {
                let url = `${OperationsApiUrl}/api/v1/vehiculo/placa?placa=${placa}`;
                let response = await axios.get(url);
                let vehiculoId = response.data.vehiculoId;

                url = `${ProductApiUrl}/api/v1/producto/ultimaventa?vehiculoId=${vehiculoId}`;
                response = await axios.get(url);
                setProducts(response.data);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                
                if(error.response.status === 404) {
                    setError('No se encontro la placa');
                }
                else {
                    setError(error.response.data);
                }
            }
            
        }
    }

    return (
        <Dialog aria-labelledby="sticker-dialog" open={props.open} maxWidth="md">
            <DialogTitle id="sticker-dialog">BUSCAR CON STICKER DE SEGURIDAD</DialogTitle>
            <DialogContent dividers>
                <div>
                    <TextField style={{ width: 145 }}
                        margin="dense"
                        id="placa-client"
                        name="placa"
                        label="PLACA"
                        value={placa}
                        onChange={onFieldChange}
                        onKeyPress={onTxtPlacaKeyPress}
                        disabled={loading}
                        error={error !== ''}
                        helperText={error}
                    />
                </div>
                { loading && (<div style={{textAlign: 'center', width: 450}} ><CircularProgress color="secondary" /></div>) }
                { !loading &&(<Table className={classes.table} size="medium">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableRow}>PRODUCTOS</TableCell>
                            <TableCell className={classes.tableRow}>CANT</TableCell>
                            <TableCell className={classes.tableRow}>TOTAL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {products.map((item, key) => (<TableRow key={key}>
                            <TableCell className={classes.tableRow}>{item.dProducto}</TableCell>
                            <TableCell className={classes.tableRow}>{item.cantidad}</TableCell>
                            <TableCell className={classes.tableRow}>{item.precioVenta*item.cantidad}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} >CERRAR</Button>
            </DialogActions>
        </Dialog>
    )

}

const mapStateToProps = state => ({
    open: state.workorder.openSticker,
});

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(StickerDialog);