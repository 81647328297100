import { OperationsApiUrl } from '../config';
import axios from 'axios';
import { actionCreators as actionWork } from './workorder';
import {
    CLOSE_MESSAGE_DIALOG,
    ERROR_MESSAGE_DIALOG,
    OPEN_MESSAGE_DIALOG,
    CLOSE_DIALOG_STATION,
    LOAD_FINISHED,
    LOAD_PIQUE_INIT,
    OPEN_DIALOG_STATION,
    REQUEST_STATION_LIST,
    RECEIVE_STATION_LIST,
    INCREMENT_SECONDS
} from '../constants/actionTypes';

const initialState = {
    open: false,
    isLoading: false,
    stations: []
};

export const actionCreators = {
    getAssignedStation: () => async (dispatch, getState) => {
        try {
            let url = `${OperationsApiUrl}/api/v1/piques/asignado`;
            let response = await axios.get(url);
            let data = response.data;

            dispatch({ type: LOAD_FINISHED });
            if(data == null || data === '') {
                dispatch({ type: LOAD_FINISHED });
                return;
            }
            dispatch({ type: LOAD_PIQUE_INIT, estacionId: data.estacionId, piqueId: data.piqueId });
            dispatch({ type: INCREMENT_SECONDS, seconds: data.tiempo })
            //despues de obtener el pique se buscan OT
            dispatch(actionWork.getWorkOrder());
        } catch (error) {
            console.log(`Error Pique: ${error}`);
        }
    },


    onCloseDialog: () => ({ type: CLOSE_DIALOG_STATION }),
    onRequestStations: () => async (dispatch, getState) => {
        if(getState().station.isLoading) {
            return;
        }
        dispatch({ type: REQUEST_STATION_LIST });

        var response = await axios.get(`${OperationsApiUrl}/api/v1/piques`);
        var stations = response.data;

        dispatch({ type: RECEIVE_STATION_LIST, stations });

    },
    onAssignStation: (piqueId) => async (dispatch) => {
        dispatch({ type: OPEN_MESSAGE_DIALOG, message: 'Asignando pique' });

        let url = `${OperationsApiUrl}/api/v1/piques/asignar`;
        let data = {
            piqueId: piqueId
        };

        try {
            await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});

            dispatch({ type: INCREMENT_SECONDS, stop: true });
            dispatch({ type: CLOSE_MESSAGE_DIALOG });
            dispatch({ type: LOAD_PIQUE_INIT, piqueId });
            dispatch(actionWork.getWorkOrder());
        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 

        
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case CLOSE_DIALOG_STATION:
            return{
                ...state,
                open: false
            };
        case OPEN_DIALOG_STATION:
            return{
                ...state,
                open: true
            };
        case REQUEST_STATION_LIST:
            return {
                ...state,
                isLoading: true
            }
        case RECEIVE_STATION_LIST:
            return {
                ...state,
                isLoading: false,
                stations: action.stations
            }
        default:
            return state;
        
    }

};