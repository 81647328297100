import React from "react";
import { ReactComponent as Activity0 } from "./activities/0.svg";
import { ReactComponent as Activity1 } from "./activities/1.svg";
import { ReactComponent as Activity2 } from "./activities/2.svg";
import { ReactComponent as Activity3 } from "./activities/3.svg";
import { ReactComponent as Activity4 } from "./activities/4.svg";
import { ReactComponent as Activity5 } from "./activities/5.svg";
import { ReactComponent as Activity6 } from "./activities/6.svg";
import { ReactComponent as Activity7 } from "./activities/7.svg";
import { ReactComponent as Activity8 } from "./activities/8.svg";
import { ReactComponent as Activity9 } from "./activities/9.svg";
import { ReactComponent as Activity10 } from "./activities/10.svg";
import { ReactComponent as Activity11 } from "./activities/11.svg";
import { ReactComponent as Activity12 } from "./activities/12.svg";
import { ReactComponent as Activity13 } from "./activities/13.svg";
import { ReactComponent as Activity14 } from "./activities/14.svg";
import { ReactComponent as Activity15 } from "./activities/15.svg";
import { ReactComponent as Activity16 } from "./activities/16.svg";
import { ReactComponent as Activity17 } from "./activities/17.svg";
import { ReactComponent as Activity18 } from "./activities/18.svg";
import { ReactComponent as Activity19 } from "./activities/19.svg";
import { ReactComponent as Activity20 } from "./activities/20.svg";
import { ReactComponent as Activity21 } from "./activities/21.svg";
import { ReactComponent as Activity22 } from "./activities/22.svg";
import { ReactComponent as Activity23 } from "./activities/23.svg";
import { ReactComponent as Activity24 } from "./activities/24.svg";
import { ReactComponent as Activity25 } from "./activities/25.svg";
import { ReactComponent as Activity26 } from "./activities/26.svg";
import { ReactComponent as Activity27 } from "./activities/27.svg";
import { ReactComponent as Activity28 } from "./activities/28.svg";
import { ReactComponent as Activity29 } from "./activities/29.svg";
import { ReactComponent as Activity30 } from "./activities/30.svg";
import { ReactComponent as Activity31 } from "./activities/31.svg";
import { ReactComponent as Activity32 } from "./activities/32.svg";
import { ReactComponent as Activity33 } from "./activities/33.svg";
import { ReactComponent as Activity34 } from "./activities/34.svg";
import { ReactComponent as Activity35 } from "./activities/35.svg";
import { ReactComponent as Activity36 } from "./activities/36.svg";
import { ReactComponent as Activity37 } from "./activities/37.svg";
import { ReactComponent as Activity38 } from "./activities/38.svg";
import { ReactComponent as Activity39 } from "./activities/39.svg";
import { ReactComponent as Activity40 } from "./activities/40.svg";
import { ReactComponent as Activity41 } from "./activities/41.svg";

const iconTypes = {
  activity0: Activity0,
  activity1: Activity1,
  activity2: Activity2,
  activity3: Activity3,
  activity4: Activity4,
  activity5: Activity5,
  activity6: Activity6,
  activity7: Activity7,
  activity8: Activity8,
  activity9: Activity9,
  activity10: Activity10,
  activity11: Activity11,
  activity12: Activity12,
  activity13: Activity13,
  activity14: Activity14,
  activity15: Activity15,
  activity16: Activity16,
  activity17: Activity17,
  activity18: Activity18,
  activity19: Activity19,
  activity20: Activity20,
  activity21: Activity21,
  activity22: Activity22,
  activity23: Activity23,
  activity24: Activity24,
  activity25: Activity25,
  activity26: Activity26,
  activity27: Activity27,
  activity28: Activity28,
  activity29: Activity29,
  activity30: Activity30,
  activity31: Activity31,
  activity32: Activity32,
  activity33: Activity33,
  activity34: Activity34,
  activity35: Activity35,
  activity36: Activity36,
  activity37: Activity37,
  activity38: Activity38,
  activity39: Activity39,
  activity40: Activity40,
  activity41: Activity41,
};

const IconComponent = ({ name, ...props }) => {
  let IconActivity = iconTypes[name];
  return <IconActivity {...props} />;
};

export default IconComponent;