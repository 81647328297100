import { OperationsApiUrl } from '../config';
import axios from 'axios';
import { actionCreators as actionStation } from './station';
import { actionCreators as actionWork } from './workorder';
import {
    ERROR_MESSAGE_DIALOG,
    LOAD_FINISHED,
    RECEIVE_ACTIVITIES_LIST,
    SET_ACTIVITY_INDEX,
    TOGGLE_SHOW_ORDER,
    UPDATE_FIELD_CLIENT_POPUP
} from '../constants/actionTypes';

const initialState = {
    activities: [],
    index: 0
};

export const actionCreators = {
    getActivities: (procesoId) => async (dispatch, getState) => {
        try {
            let url = `${OperationsApiUrl}/api/v1/actividades?procesoId=${procesoId}`;
            let response = await axios.get(url);
            let data = response.data;

            dispatch({ type: RECEIVE_ACTIVITIES_LIST, activities: data });
            dispatch({ type: LOAD_FINISHED });
            dispatch(actionStation.getAssignedStation());
        } catch (error) {
            console.log(`Error Actividades: ${error}`);
        }
    },
    decreaseIndex: () => (dispatch, getState) => {
        let index = getState().process.index;

        if(index === 0) {
            return;
        }
        dispatch(actionCreators.updateActivityWorkOrder(index));
        dispatch({ type: SET_ACTIVITY_INDEX, index: index - 1 });
    },
    increaseIndex: () => (dispatch, getState) => {
        let index = getState().process.index;
        let activity = getState().process.activities[index];

        //Ejecutamos acciones para index actual
        if(activity.requiereCliente && getState().client.vehiculo.vehiculoId === 0 && getState().workorder.iniciado){
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Es obligatorio ingresar datos del vehículo.' });
            return;
        }
        if(activity.requiereCliente && getState().client.cliente.clienteId === 0 && getState().workorder.iniciado){
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Es obligatorio ingresar datos del cliente.' });
            return;
        }
        if(activity.requiereCliente && getState().client.vehiculo.kilometraje === '' && getState().workorder.iniciado){
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'Es obligatorio ingresar el kilometraje del vehículo.' });
            return;
        }
        if(activity.orden > 8 && getState().workorder.ordenTrabajoId === 0 && getState().workorder.iniciado ) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: 'No se ha podido crear la orden de trabajo, no puede continuar con el proceso.' });
            return; 
        }

        if(activity.requiereCliente && getState().workorder.iniciado) {
            dispatch(actionWork.createWorkOrder());
        }
        //
        //Ejecutamos acciones para nuevo index
        activity = getState().process.activities[index + 1];
        if(activity.enciendeLuz){
            document.getElementById('timbre').play();
        }
        if(activity.requiereCliente) {
            dispatch({ type: UPDATE_FIELD_CLIENT_POPUP, key: 'kilometraje', value: '' });
        }
        //
        if( getState().workorder.showOrder ) {
            dispatch({ type: TOGGLE_SHOW_ORDER});
        }
        dispatch({ type: SET_ACTIVITY_INDEX, index: index + 1 });
        dispatch(actionCreators.updateActivityWorkOrder(index + 1));
    },
    updateActivityWorkOrder: (index) => async (dispatch, getState) => {
        if(getState().workorder.ordenTrabajoId === 0 || index < 0) {
            return;
        }
        let activity = getState().process.activities[index];
        let data = {
            ordenTrabajoId: getState().workorder.ordenTrabajoId,
            actividadId: activity.actividadId,
            ejecutado: true,
            horaInicio: new Date()
        };

        try {
            let url = `${OperationsApiUrl}/api/v1/ordentrabajo/dordentrabajoactividad`;
            await axios.post(url, data, {headers: {'Content-Type': 'application/json'}});
            
        } catch (error) {
            dispatch({ type: ERROR_MESSAGE_DIALOG, message: error.response.data.message.toString() });
        } 
    }
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch(action.type){
        case RECEIVE_ACTIVITIES_LIST:
            return {
                ...state,
                activities: action.activities
            };
        case SET_ACTIVITY_INDEX:
            return {
                ...state,
                index: action.index
            }
        default:
            return state;
    }
};